import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { addToCartAction, cartAction, wishlistAction, addToWishlistAction, breadCrumbAction, removeFromCartAction, modalAction } from '../../../../actions';
// import { useHistory } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import axios from 'axios';
// import Alert from 'react-bootstrap/Alert';
// import { ModalComponent, handleNumberInputs } from './utilities';
import { Link } from 'react-router-dom';
import { ConnectedCartCardM } from '../mobileView/cards';
import { AutoComplete, getFallbackImg, getFrom, stringToast, updateLocalStorageItems } from '../utilities';
import { ConnectedSearchListCard } from '../cards';
import { useCallback } from 'react';
import { useState } from 'react';
import { hasTable } from '../../../../constants';



const EditCartPage = ({ breadCrumbAction, cartAction, modalAction, wishlistAction, cart, isMobile, globalData, compCode, userInfo, compInfo, isLoggedIn, modals }) => {

    const data = modals.CART_EDIT_MODAL.data;

    console.log(data);
    

    const handleClose = () => {
        modalAction('CART_EDIT_MODAL', false, {}); 
        modalAction('TABLE_SELECTION_MODAL', true);
    }
   
    return (
        <div id="target" className='d-flex justify-content-center' style={{fontFamily: 'Lato'}}>
            <style>
                {`
                    @media print {
                        .btnPrint {display: none;}
                    }
                `}
            </style>
            <div id="invoice-POS" style={{border: '1px solid #e9e9e9', padding: '1px', margin: '0 auto', width: '70mm', background: '#FFF', fontFamily: 'Arial, Helvetica, sans-serif'}}>
                <center id="top">
                    <div className="btnPrint mb-2" style={{textAlign: 'center'}}>
                        <span className='btn btn-secondary px-2 py-1 me-3' onClick={() => window.print()}><i class='bx bxs-printer'></i> Print</span>
                        <span className='btn btn-secondary px-2 py-1' onClick={handleClose}><i class='bx bx-x'></i> Close</span>
                    </div>
                <div className="logo" />
                <div className="info">
                    <h6 style={{marginBottom: 0, marginTop: 0}}>
                        <font style={{fontSize: '16px', fontWeight: 'bold'}}> {compInfo.COMPNAME}</font>
                    </h6>
                </div>
                </center>
                <div id="mid" style={{borderBottom: '1px solid #bbb3b3'}}>
                    <div className="info">
                        <h2 style={{textAlign: 'center', fontSize: '1.1em'}} className='my-2 fw-bold'>Order List</h2>
                        <table style={{width: '100%', fontSize: '13px'}}>
                            <tbody>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Order #</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {data.orderNo}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Order Date</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {new Date().toLocaleDateString('en-TT')} &nbsp;&nbsp; {new Date().getHours()} : {new Date().getMinutes()}</td>
                                </tr>
                                <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Table No</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {data.tableNo}</td>
                                </tr>
                                {data.waiterName && <tr>
                                    <td style={{width: '35%', fontWeight: 'bold'}}>Waiter Name</td>
                                    <td style={{width: '65%', fontWeight: 'bold'}}>:&nbsp; {data.waiterName}</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="bot">
                    <div id="table">
                        <table className='w-100'>
                        <tbody><tr className="tabletitle" style={{borderBottom: '1px solid #bbb3b3'}}>
                            <td style={{verticalAlign: 'top', fontWeight: 'bold'}}>#</td>
                            <td style={{verticalAlign: 'top', fontWeight: 'bold'}} className="item">Particulars</td>
                            <td style={{verticalAlign: 'top', fontWeight: 'bold'}} className="Hours" align="right">Qty</td>
                            </tr>
                            {data.orderItems?.map((i, n) => (
                                <tr className="service" style={{borderBottom: '1px solid #EEE'}}>
                                    <td className="tableitem" style={{verticalAlign: 'top', width: '8%'}}><p className="itemtext" style={{fontWeight: 'bold', margin: '0'}}>{n+1}.</p></td>
                                    <td className="tableitem" style={{verticalAlign: 'top'}}>
                                        <p className="itemtext" style={{fontWeight: 'bold', whiteSpace: 'nowrap', margin: '0'}}>
                                            {i.Description}
                                        </p>
                                        <p style={{margin: 0, fontSize: '11px'}}></p>
                                    </td>
                                    <td className="tableitem" align="right" style={{verticalAlign: 'top'}}>
                                        <p className="itemtext" style={{fontWeight: 'bold', margin: '0'}}>
                                            {i.BillQty}
                                        </p>
                                    </td>
                                </tr>
                            ))}
                            {/* <tr className="service" style={{borderBottom: '1px solid #EEE'}}>
                                <td className="tableitem" style={{verticalAlign: 'top', width: '8%'}}><p className="itemtext" style={{fontWeight: 'bold', margin: '0.3em 0'}}>1.</p></td>
                                <td className="tableitem" style={{verticalAlign: 'top'}}>
                                    <p className="itemtext" style={{fontWeight: 'bold', whiteSpace: 'nowrap', margin: '0.3em 0'}}>
                                    BESAN .
                                    </p>
                                    <p style={{margin: 0, fontSize: '11px'}}></p>
                                </td>
                                <td className="tableitem" align="right" style={{verticalAlign: 'top'}}>
                                    <p className="itemtext" style={{fontWeight: 'bold', margin: '0.3em 0'}}>
                                    1
                                    </p>
                                </td>
                            </tr>
                            <tr className="service" style={{borderBottom: '1px solid #EEE'}}>
                            <td className="tableitem" style={{verticalAlign: 'top', width: '8%'}}><p className="itemtext" style={{fontWeight: 'bold', margin: '0.3em 0'}}>2.</p></td>
                            <td className="tableitem" style={{verticalAlign: 'top'}}>
                                <p className="itemtext" style={{fontWeight: 'bold', whiteSpace: 'nowrap', margin: '0.3em 0'}}>
                                MASALA RICE WT RAITA .
                                </p>
                            </td>
                            <td className="tableitem" align="right" style={{verticalAlign: 'top'}}>
                                <p className="itemtext" style={{fontWeight: 'bold', margin: '0.3em 0'}}>
                                1
                                </p>
                            </td>
                            </tr> */}
                        </tbody></table>
                    </div>
                    <div id="legalcopy">
                        <div className="info" style={{textAlign: 'center'}}>
                        </div>
                    </div>
                    <div className="btnPrint mt-2" style={{textAlign: 'center'}}>
                        <span className='btn btn-secondary px-2 py-1 me-3' onClick={() => window.print()}><i class='bx bxs-printer'></i> Print</span>
                        <span className='btn btn-secondary px-2 py-1' onClick={handleClose}><i class='bx bx-x'></i> Close</span>
                    </div>
                </div>
            </div>
        </div>

    )  
}

const mapStateToPropsTwo = (state) => {
  return { cart: state.cart, compCode: state.compCode, isMobile: state.isMobile, globalData: state.globalData, userInfo: state.userInfo, compInfo: state.compInfo, isLoggedIn: state.isLoggedIn, modals: state.modals };
}

export default connect(mapStateToPropsTwo, {breadCrumbAction, cartAction, wishlistAction, modalAction})(EditCartPage);