import React, { useEffect, useRef, useState, useMemo } from 'react';
import { ModalComponent, getFrom, handleNumberInputs, updateLocalStorageItems, Spinner, focusArea, createDate, AutoComplete, wait } from './utilities';
import { breadCrumbAction, loginStatusAction, userInfoAction, loaderAction, modalAction, cartAction, myOrdersAction, globalDataAction } from '../../../actions';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { hasTable, RAJE_RESTAURANT_ID, TAKE_HOME_ID, XYZ_ID } from '../../../constants';
import { ConnectedSearchListCard, WaiterCard } from './cards';
import { ConnectedCartCardM } from './mobileView/cards';

const Checkout = ({ breadCrumbAction, cart, isLoggedIn, userInfo, compCode, loaderAction, modalAction, cartAction, globalData, globalDataAction, modals }) => {

  const history = useHistory();
  const locationId = globalData.location.LocationId;

  useEffect(() => {
    if (!locationId) {
        if (hasTable) return;
        alert('Please select a Service Location before we proceed with your order..');
        history.goBack();
        return;
    } else if (!isLoggedIn) {
        modalAction('LOGIN_MODAL', true);
        return;
    } else {
        modalAction('LOGIN_MODAL', false);
        if (hasTable) {
            setDeliverable(true);
        } else {
            setLocationModalActive(true);
        }
    }
  }, [userInfo.Pin, locationId, isLoggedIn])

  useEffect(() => {
    if (compCode === XYZ_ID || hasTable) return;
    let cartArray = Object.values(cart.pharmacy);                                                               
    let stockQtyList = cartArray.map(item => item.StockQty);                      
    if (stockQtyList.includes(0)) {
        alert('Please Remove or move to wishlist, out of Stock Products in your cart to continue.');
        history.push('/cartPage');
    }
  }, [])

// -------------- Prescription start ------------------------------------------------------------------------------------------------
  const prescription = globalData.prescription;
  
  useEffect(() => {						
      window.renderVenoBox();
  },[prescription.src])
    
// -------------- Prescription ends ------------------------------------------------------------------------------------------------
  useEffect(() => {
    breadCrumbAction({links: [{name: 'Home', link: '/'}, {name: 'Checkout', link: '/checkout'}], activeLink: '/checkout'});
  },[breadCrumbAction])  

  const cartArray = Object.values(cart.pharmacy);                                                               // Convert cart object into list.
  const cartItemsValueList = cartArray.map(item => item.count * item.SRate);                           // Array of all item's price * quantity selected.
  const cartSubtotal = parseFloat(cartItemsValueList.reduce((total, num) => total + num, 0).toFixed(2));         // .toFixed converts it into string so using parsefloat.
//   const [couponTab, setCouponTab] = useState(false);

  const [orderData, setOrderData] = useState({
    PartyCode: '',
    InsBy: '',              
    PaymentMethod: 'COD',
    Amount: '',
    EncCompanyId: '',
    SalesDetailsList: [],                               

    BillingState: userInfo.State,
    BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
    DeliveryParty: userInfo.PartyCode,
    DeliveryState: userInfo.State,
    DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,

  });
  
  const [locationModalActive, setLocationModalActive] = useState(false);
  const [isDeliverable, setDeliverable] = useState(false);
  const [selectedServiceLocation, setSelectedServiceLocation] = useState({ LocationName: '', Address: '', PIN: '' });
  
  let orderList = useMemo(() => Object.values(cart.pharmacy).map(i => ({             // since we need value (array) from orderList hence using useMemo instead of useCallback which return function. both can suppress useEffect dependency warning.
      Description: i.Description,      
      BillQty: i.count,                                                              // We can't use cartArray defined above. because it cartArray recalculated on every page render and usecallback thinks it's been changed  
      ItemId: i.ItemId,                                                              // and hence triggers recalculation / re-run of orderList function which makes below useEffect to reRender the page (again recalculates cartArray) resulting falling in a loop.
      AutoId: i.AutoId,
      Unit: i.Unit,
      MRP: i.ItemMRP,
      MRPOnDisPer: i.DiscountPer,
      Rate: (((i.count * i.SRate) - (((i.count * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))))/i.count).toFixed(2),
      PackSizeId: i.PackSizeId ? i.PackSizeId : 0,
      Amount: i.count * i.SRate,
      TaxableAmount: ((i.count * i.SRate) - ((i.count * i.SRate * i.IGSTRATE) / (i.IGSTRATE + 100))).toFixed(2),
      CGSTRATE: i.CGSTRATE,
      SGSTRATE: i.SGSTRATE,
      IGSTRATE: i.IGSTRATE
  })),[cart])  

  const restaurantTable = globalData.restaurant.table;
  
  useEffect(() => {
      async function init() {   
          if (isLoggedIn) {
            let cashPartyName;
            let cashPartyMobile; 
            if (hasTable) {
                cashPartyName = '';
                cashPartyMobile = '';
            } else {
                cashPartyName = prescription.patient.name || userInfo.Name;
                cashPartyMobile = prescription.patient.phone || userInfo.RegMob1;  
            }

            setOrderData((preValues) => ({
                ...preValues,
                PartyCode: userInfo.PartyCode,
                InsBy: userInfo.UserId,              
                PaymentMethod: 'COD',
                Amount: cartSubtotal,
                EncCompanyId: compCode,
                SalesDetailsList: orderList,                       
                BillingState: userInfo.State,
                BillingAddress: userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
                DeliveryParty: userInfo.PartyCode,
                DeliveryState: userInfo.State,
                DeliveryAddress : userInfo.Address + ' ' + userInfo.Address2 + ' ' + userInfo.Pin,
                LocationId: locationId,
                filesToUpload: prescription,

                PartyCode : userInfo.PartyCode,
                DeliveryParty: userInfo.selectedMember.PartyCode || 'undefined',
                ReferenceBy: prescription.patient.docName || '',
                DoctorLocation: prescription.patient.docAddress || '',

                CashPartyName: cashPartyName,
                CashPartyMobile: cashPartyMobile,

                // --------- NEW FIELDS FOR RESTAURANT STARTS ---------------------------------------

                BillId: restaurantTable?.ProvInvBillid,
                BedId: restaurantTable?.BedId,
                CollectedById: '',
                
                // --------- NEW FIELDS FOR RESTAURANT ENDS ---------------------------------------

                AccPartyMemberMaster: {
                    Salutation: '',
                    MemberName : prescription.patient.name || userInfo.Name,
                    EncCompanyId: compCode,    
                    RegMob1: userInfo.RegMob1,
                    Gender: prescription.patient.gender?.CodeId || userInfo.Gender,
                    GenderDesc: prescription.patient.gender?.GenderDesc || userInfo.GenderDesc,
                    Address: prescription.patient.address || userInfo.Address,
                    Age: prescription.patient.age || userInfo.Age,
                    AgeMonth: '0',
                    AgeDay: '0',           

                    State: prescription.patient.state?.CodeId || userInfo.State,
                    City: prescription.patient.city || userInfo.City,
                    Pin: prescription.patient.pinCode || userInfo.Pin,
                    Landmark: '',

                    ParentUserId: userInfo.UserId,
                    MemberId: prescription.patient.memberId || userInfo.MemberId,
                    MemberTypeId : 0,
                    UserType: userInfo.UserType,
                    UID: '',
                    UserId: 0,
                    
                    DOB: prescription.patient.age ? createDate(0, 0, prescription.patient.age) : new Date(userInfo.DOB).toLocaleDateString('en-TT'),
                    DOBstr: prescription.patient.age ? createDate(0, 0, prescription.patient.age) : new Date(userInfo.DOB).toLocaleDateString('en-TT'),
                    IsDOBCalculated: 'N',
                    Aadhaar: '',
                    ParentAadhaar1: '',
                    ParentAadhaar2: '',
                    RelationShipWithHolder: '',
                    Mobile: prescription.patient.phone || userInfo.RegMob1,
                    Country: 1
                },

                EnclosedDocObj: {
                    EnclosedDocList: [
                        {
                            EnclosedId: '0',
                            AppId: '0',
                            EmpId: prescription.patient.memberId || userInfo.MemberId,              // memberid ||  || ||0
                            AppType: '',
                            EncloserType: '',
                            EncloserTypeDesc: '',
                            FileName: '',
                            FilePath: '',
                            Description: '',
                            Remarks: prescription.imgName || '',            // img nam || name
                            EnclosedDate: '',
                            EnclosedTime: '',
                            EnclosedDocList: '',
                            EnclosedDeleteDocList: '',
                        }
                    ]
                }
            }))

        } else {
            setOrderData((preValues) => ({
                ...preValues,
                PartyCode: '0',
                InsBy: '0',
                BillingState: '',
                BillingAddress: '',
                DeliveryParty: '',
                DeliveryState: '',
                DeliveryAddress : '',
                filesToUpload: {},
                
                PartyCode : '',
                DeliveryParty: '',
                ReferenceBy: '',
                DoctorLocation: '',
            }))
        }
    }
    init();
  },[isLoggedIn, userInfo, cartSubtotal, compCode, orderList, locationId, prescription.src, restaurantTable?.ProvInvBillid, restaurantTable?.BedId])

    const getBase64 = (file) => new Promise(function (resolve, reject) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject('Error: ', error);
    })  
  
  const placeOrder = async () => {
    if (!isLoggedIn) return alert('please login to place an order.');
    if (!orderData.LocationId) return alert('Please select a Service Location before making an order.');
    if (!orderData.SalesDetailsList.length) return alert('Add something to your cart before making an order.');
    
    let body = { ...orderData };

    if (compCode === TAKE_HOME_ID) {
        if (!orderData.filesToUpload.src) return alert('Please add your prescription to place your order.');
    } else if (hasTable) {
        delete body.EnclosedDocObj;
        // if (!restaurantTable?.BedId) return alert('Please select a waiter to place an order.');
        // if (!selectedWaiter.PartyCode) return alert('Please select a waiter to place an order.');
    }
    

    if (prescription.src) {
        const prescImg = new FormData();
        const file = await fetch(orderData.filesToUpload.src).then(r => r.blob());
        prescImg.append('image', file);
        prescImg.append('imgName', orderData.filesToUpload.imgName);
        let imgString = await getBase64(prescImg.get('image'));
        let target = imgString.indexOf('base64,');
        imgString = imgString.slice(target + 7);                                 // remove prefix.
        
        delete body.filesToUpload;
        body.EnclosedDocObj.EnclosedDocList[0].filesToUpload = imgString;        
    } else {
        delete body.filesToUpload;
    } 

    try {
        loaderAction(true);
        const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/Pharma`, body);
        console.log(res.data); 
        loaderAction(false);
        cartAction('DUMP_CART', {}, 'pharmacy');
        updateLocalStorageItems();
        if (hasTable) {
            if (!(res.data).includes("KOT")) {console.log(body, res.data); return alert('Failed to Place Order.');};
            modalAction('CART_EDIT_MODAL', true, { orderItems: orderList, tableNo: restaurantTable?.BedDesc, tableId: restaurantTable?.BedId, orderNo: res.data, orderDate: previousOrder.data.SalesObj.VchDate, waiterName: selectedWaiter.Name });
            globalDataAction({ restaurant: { table: {} } });
            setPreviousOrder({loading: false, data: { SalesObj: { SalesDetails: [] } }, err: {status: false, msg: ''}});
            setCustomer({ customerName: '', customerPhone: '' });
            setSelectedWaiter({ Name: '', PartyCode: '' });
        } else {
            modalAction('ORDER_SUCCESS_MODAL', true);
            globalDataAction({ prescription: { patient: {docName: '', docAddress: '' }} });
        }
    } catch (err) {
        console.log(err);
        return false;
    }
    console.log(body);
  }

  const closeModal = () => setLocationModalActive(false);

  useEffect(() => {
    if (!locationId) return;
    setSelectedServiceLocation(globalData.location);
  }, [globalData.location])

//   ---------------------------------------------------------------------------------------------------------------------------------------

    const [searchTerm, setSearchTerm] = useState({query: '', filterTerm: 'All', filterId: 0});    
    const [searchList, setSearchList] = useState({loading: false, data: [], err: {status: false, msg: ''}});
    const [searchResultsActive, setSearchResultsActive] = useState(false);
    const [selectedWaiter, setSelectedWaiter] = useState({ Name: '', PartyCode: '' });
    const [customer, setCustomer] = useState({ customerName: '', customerPhone: '' });



    const handleSearch = (e) => {
        const { name, value } = e.target;
        setSearchTerm(pre => ({...pre, [name]: value}));
        if (value === '') setSelectedWaiter({ Name: '', PartyCode: '' }); 
    }

    console.log(orderData);
    

    useEffect(() => {
    if (!hasTable) return;
    const getSearchResult = async (companyCode, key) => {                      
        if (!companyCode) return alert('no companyCode received');                  
        const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/Api/Values/Get?CID=${companyCode}&type=CollectedBy&prefixText=${key.query}&Specialist=0`, {}, setSearchList);
        if (res) {                                                                    
            setSearchList(res);
        } else {
            console.log('No data received');
        }
    }  
    const timer = setTimeout(() => {
        if (searchTerm.query.length < 1) return;
        getSearchResult(compCode, searchTerm);  
    }, 500);
    return () => clearTimeout(timer);
    }, [searchTerm, compCode, locationId])

    const handleSelect = (i) => {
        setSelectedWaiter(i);
        setSearchTerm(pre => ({ ...pre, query: i.Name}));
        setSearchResultsActive(false);
    }

    const selectMember = () => {
        if (!isLoggedIn) return modalAction('LOGIN_MODAL', true);
        modalAction('MEMBER_SELECT_MODAL', true);
    }  
    
    const [previousOrder, setPreviousOrder] = useState({loading: true, data: { SalesObj: { SalesDetails: [] } }, err: {status: false, msg: ''}});
    const [viewItems, setViewItems] = useState(true);

    let previousOrderItems = useMemo(() => previousOrder.data.SalesObj.SalesDetails.map(i => ({       
        BillQty: i.BillQty,                                                              
        ItemId: i.ItemId,                                                              
        Unit: i.Unit,
        AutoId: i.AutoId,
        MRP: i.ItemMRP,
        MRPOnDisPer: '',
        Rate: (((i.BillQty * i.Rate) - (((i.BillQty * i.Rate * i.IGSTRATE) / (i.IGSTRATE + 100))))/i.BillQty).toFixed(2),
        PackSizeId: i.PackSizeId ? i.PackSizeId : 0,
        Amount: i.BillQty * i.Rate,
        TaxableAmount: ((i.BillQty * i.Rate) - ((i.BillQty * i.Rate * i.IGSTRATE) / (i.IGSTRATE + 100))).toFixed(2),
        CGSTRATE: i.CGSTRATE,
        SGSTRATE: i.SGSTRATE,
        IGSTRATE: i.IGSTRATE,
    })),[previousOrder.data.SalesObj.SalesDetails.length, restaurantTable?.BedId])                          // previousOrder depends on table Id. 
    
    const previousValueList = previousOrderItems.map(item => item.BillQty * item.Rate);
    const previousTotal = parseFloat(previousValueList.reduce((total, num) => total + num, 0).toFixed(2)); 
    
    useEffect(() => {
        if (!hasTable) return;
        const getPreviousOrder = async (CID, LOCID, BillId, TableId) => {
            if (CID, LOCID, BillId, TableId) {
                const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Pharma/GetKOTDetails?CID=${CID}&LOCID=${LOCID}&BillId=${BillId}&TableId=${TableId}`, {}, setPreviousOrder);
                if (res) {              
                    setPreviousOrder(res);                       
                }
            }
        }  
        getPreviousOrder(compCode, locationId, restaurantTable.ProvInvBillid, restaurantTable.BedId)
    }, [compCode, locationId, restaurantTable?.ProvInvBillid, restaurantTable?.BedId])

    useEffect(() => { 
        if (!hasTable) return;
        console.log('totalAmount recalculated: ', cartSubtotal + previousTotal);
        setOrderData(pre => ({
            ...pre,
            CollectedById: selectedWaiter.PartyCode,
            CashPartyName: customer.customerName,
            CashPartyMobile: customer.customerPhone,
            VchNo: previousOrder.data.SalesObj.VchNo, 
            SalesDetailsList: [ ...orderList,  ...previousOrderItems ],
            Amount: cartSubtotal + previousTotal
        }))
    }, [selectedWaiter.PartyCode, customer.customerName, customer.customerPhone, previousTotal, cartSubtotal, restaurantTable?.BedId])      // depends on table Id.

    useEffect(() => {
        if (!hasTable) return;
        if (restaurantTable?.ProvInvBillid) {
            setCustomer({ customerName: previousOrder.data.SalesObj.CashPartyName, customerPhone: previousOrder.data.SalesObj.CashPartyMobile });
            setSelectedWaiter({ PartyCode: previousOrder.data.SalesObj.CollectedById, Name: previousOrder.data.SalesObj.CollectedBy });
            setSearchTerm({ query: previousOrder.data.SalesObj.CollectedBy || '', filterTerm: 'All', filterId: 0 });
        } else {
            setCustomer({ customerName: '', customerPhone: '' });
            setSelectedWaiter({ PartyCode: '', Name: '' });
            setSearchTerm({ query: '', filterTerm: 'All', filterId: 0 });
        }
    }, [previousOrder.data.SalesObj.BillId, restaurantTable?.BedId])

  return (                                                                                                      
    <>
        <div className="checkout-area pt-2 pt-lg-3 pb-30 epharma-global">
            <div className="container">
                <div className="row mt-0 mt-lg-3">
                    {/* {hasTable && <div className="col-12">
                        <div className='d-flex flex-column-reverse flex-md-row justify-content-between pb-3 mb-3 gap-4' style={{fontSize: 'clamp(0.9em, 2.7vw, 0.93em)', padding: '1em', background: 'aliceblue', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px'}}>  
                            {restaurantTable?.ProvInvBillid ? <div className='me-auto d-flex flex-wrap align-items-center' style={{gap: '1.3em 2em'}}>
                                <h5 className="mb-0 d-flex align-items-center lh-0" style={{fontSize: '1.1em', gap: '0.3em'}}><i className='bx bxs-grid-alt'></i> Order ID: {previousOrder.data.SalesObj.VchNo}</h5>
                                {previousOrder.data.SalesObj.CashPartyName && <h5 className="mb-0 d-flex align-items-center lh-0" style={{fontSize: '1.1em', gap: '0.3em'}}><i className='bx bxs-user'></i> {previousOrder.data.SalesObj.CashPartyName}</h5>}
                                {previousOrder.data.SalesObj.CashPartyMobile && <h5 className="mb-0 d-flex align-items-center lh-0" style={{fontSize: '1.1em', gap: '0.3em'}}><i className='bx bxs-phone-call'></i> {previousOrder.data.SalesObj.CashPartyMobile}</h5>}
                                <h5 className="mb-0 d-flex align-items-center lh-0 text-danger" style={{fontSize: '1.1em', gap: '0.5em'}} role='button' onClick={() => setViewItems(!viewItems)}><i className='bx bx-plus rounded-circle' style={{outline: '2px solid'}}></i> VIEW ITEMS</h5>
                            </div> : ''}
                            <div className='d-flex align-items-center justify-content-end flex-wrap' style={{gap: 'clamp(1em, 2.7vw, 2.5em)', fontSize: 'clamp(0.9em, 3vw, 1em)', flex: 1}}>   
                                <h5 className="mb-0 d-flex align-items-center lh-0" style={{fontSize: '1.1em', gap: '0.3em', color: '#005ee9'}} role='button' onClick={() => modalAction('TABLE_SELECTION_MODAL', true)}><i className='bx bxs-grid-alt'></i> {restaurantTable ? <>{restaurantTable?.BedDesc}, &nbsp; {restaurantTable?.BedGroupDesc}</> : 'Select Table'} <i style={{fontSize: '2rem', color: '#f17e1d'}} className='bx bx-caret-down'></i></h5>
                                <h5 className="mb-0 align-items-center lh-0" style={{fontSize: '1.1em', gap: '0.3em', color: '#ef008d', display: isLoggedIn ? 'flex' : 'none'}}><i className='bx bxs-user'></i> {userInfo.selectedMember.MemberName}</h5>
                                <span onClick={selectMember} className='continue-button d-inline' style={{background: '#1f8dc9', padding: '0 7px', lineHeight: '2.3', borderRadius: '6px'}} role='button'>Select Entity</span>
                            </div>
                        </div>
                    </div>} */}
                    <div className="col-lg-5 col-12">
                        {!isLoggedIn && <h3>Please <span className='text-decoration-underline text-primary' role='button' onClick={() => modalAction('LOGIN_MODAL', true)}>Login</span> to place an order.</h3>}
                        {isLoggedIn && <div>
                            {(() => {
                                if (hasTable) {
                                    return (
                                        <>                                        
                                            <div className='shipping-details p-4 px-lg-0'>
                                                <div className='d-flex justify-content-between align-items-center mb-3 mb-lg-4'>
                                                    <h5 className='mb-0' style={{fontSize: '1.9rem'}} onClick={() => console.log(orderData)}>Billing Details</h5>
                                                    <h5 className="mb-0 align-items-center lh-0" style={{fontSize: '1em', gap: '0.3em', color: '#ef008d', display: isLoggedIn ? 'flex' : 'none'}}><i className='bx bxs-user' style={{fontSize: '1em', color: '#ef008d'}}></i> {userInfo.selectedMember.MemberName} <i style={{verticalAlign: 'sub', color: '#f17e1d'}} role='button' className='ms-1 bx bx-caret-down' onClick={selectMember}></i></h5>
                                                </div>
                                                <div className="your-order-table user-details table-responsive overflow-visible">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th className="cart-product-name text-nowrap">Table : &nbsp;&nbsp;&nbsp;</th>
                                                                <th className="cart-product-total" style={{width: '73%'}}>
                                                                    {restaurantTable?.BedId ? 
                                                                        <span style={{color: '#005ee9'}} role='button' onClick={() => modalAction('TABLE_SELECTION_MODAL', true)}>{restaurantTable?.BedDesc}, &nbsp; {restaurantTable?.BedGroupDesc} <i style={{verticalAlign: 'sub', color: '#f17e1d'}} className='bx bx-caret-down'></i></span>
                                                                        : 
                                                                        <span style={{color: '#005ee9'}} role='button' onClick={() => modalAction('TABLE_SELECTION_MODAL', true)}>Select Table <i style={{verticalAlign: 'sub', color: '#f17e1d'}} className='bx bx-caret-down'></i></span>
                                                                    }
                                                                    {/* <h5 className="mb-0 d-flex align-items-center lh-0" style={{fontSize: '1.1em', gap: '0.3em', color: '#005ee9'}} role='button' onClick={() => modalAction('TABLE_SELECTION_MODAL', true)}><i className='bx bxs-grid-alt'></i> {restaurantTable ? <>{restaurantTable?.BedDesc}, &nbsp; {restaurantTable?.BedGroupDesc}</> : 'Select Table'} <i style={{fontSize: '2rem', color: '#f17e1d'}} className='bx bx-caret-down'></i></h5> */}
                                                                </th> 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {restaurantTable?.ProvInvBillid ? <tr className="cart_item">
                                                                <td className="cart-product-name text-nowrap">
                                                                    <strong className="product-quantity">Order ID : </strong>
                                                                </td>
                                                                <td className="cart-product-total">
                                                                    <div className='d-flex justify-content-between py-0 py-lg-1'>
                                                                        <span className="amount fw-bold mt-1">{previousOrder.data.SalesObj.VchNo}</span>
                                                                        <h6 className='mb-0 fw-bold' style={{color: '#007bff', cursor: 'pointer', borderBottom: '2px solid #007fff', fontSize: '0.9em'}} onClick={() => setViewItems(!viewItems)}>
                                                                            <i className={`bx bx-${viewItems ? 'minus' : 'plus'}-circle rounded-circle`} style={{color: '#007bff', fontSize: '1.9rem', verticalAlign: 'middle'}}></i>&nbsp;View Items
                                                                        </h6>
                                                                    </div>
                                                                </td>
                                                            </tr> : ''}
                                                            <tr className="cart_item">
                                                                <td className="cart-product-name text-nowrap">Select Waiter <strong className="product-quantity"> : </strong></td>
                                                                <td className="cart-product-total">
                                                                    <form>
                                                                        <div className="input-group flex-nowrap position-relative">
                                                                            <input className="form-control fw-bold"  onChange={handleSearch} onClick={() => setSearchResultsActive(true)} value={searchTerm.query} name="query" type="text" placeholder="Search Waiter" />
                                                                            <span className="input-group-text" id="basic-addon2" >Search</span>
                                                                            {searchResultsActive && <AutoComplete name='search-results' list={searchList.data} isLoading={searchList.loading} setActive={setSearchResultsActive} styles={{fontSize: '0.9em'}} children={<WaiterCard handleSelect={handleSelect} />} keyName={'PartyCode'} />}
                                                                        </div>
                                                                    </form>
                                                                </td>
                                                            </tr>
                                                            {/* <tr className="cart_item">
                                                                <td className="cart-product-name fw-bold text-dark"> Selected Waiter <strong className="product-quantity"> : </strong></td>
                                                                <td className="cart-product-total">
                                                                    <div className='d-flex justify-content-between align-items-start gap-3'>
                                                                        <div>
                                                                            <span className="amount fw-bold text-dark d-block mb-2">{userInfo.selectedMember.MemberName}</span>
                                                                            <span className="amount">( {userInfo.selectedMember.GenderDesc}, {userInfo.selectedMember.Age} Yrs )</span>
                                                                        </div>
                                                                        <h6 onClick={() => modalAction('MEMBER_MODAL', true, {editId: userInfo.selectedMember.MemberId})} className='mb-0 fw-bold' style={{color: '#007bff', cursor: 'pointer', borderBottom: '2px solid #007fff', fontSize: '1em'}}>
                                                                            Details&nbsp;<i className='bx bx-caret-down' style={{color: '#007bff', fontSize: '1.9rem', verticalAlign: 'middle'}}></i>
                                                                        </h6>
                                                                    </div>
                                                                </td>   
                                                            </tr> */}
                                                            <tr className="cart_item">
                                                                <td className="cart-product-name text-nowrap"> Member Name <strong className="product-quantity"> : </strong></td>
                                                                <td className="cart-product-total">
                                                                    <div className="input-group flex-nowrap">
                                                                        <input className="form-control" name='customerName' value={customer.customerName} onChange={(e) => setCustomer(pre => ({...pre, [e.target.name]: e.target.value}))} type="text" placeholder="Enter Name" />
                                                                    </div>  
                                                                </td>
                                                            </tr>
                                                            <tr className="cart_item">
                                                                <td className="cart-product-name text-nowrap"> Phone Number <strong className="product-quantity"> : </strong></td>
                                                                <td className="cart-product-total">
                                                                    <div className="input-group flex-nowrap">
                                                                        <input className="form-control" name='customerPhone' value={customer.customerPhone} maxLength={10} onChange={(e) => setCustomer(pre => ({...pre, [e.target.name]: e.target.value}))} type="text" placeholder="Enter Number" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {/* <tr className="cart_item">
                                                                <td className="cart-product-name"> E-mail <strong className="product-quantity"> : </strong></td>
                                                                <td className="cart-product-total"><span className="amount">{userInfo.Email}</span></td>
                                                            </tr> */}
                                                            {/* <tr className="cart_item">
                                                                <td className="cart-product-total" colSpan={2}>
                                                                    <Link to='#' onClick={() => console.log(customer, selectedWaiter.Name)} className='add_an_item_btn ms-auto'>EDIT</Link>
                                                                </td>                          
                                                            </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* {!selectedServiceLocation.PIN && <h3 className='d-flex justify-content-between align-items-center mt-4'>Service Location <span className='add_an_item_btn m-0' role='button' onClick={() => setLocationModalActive(true)}>SELECT</span></h3>} */}
                                            </div>
                                        </>
                                    )
                                } else {
                                    return (
                                        <div className='shipping-details p-4 px-lg-0'>
                                            <h3 className='mb-4'>Shipping Details</h3>
                                            <div className="your-order-table user-details table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="cart-product-name">Bill To : &nbsp;&nbsp;&nbsp;</th>
                                                            <th className="cart-product-total" style={{width: '73%'}}>{userInfo.Name}</th> 
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr className="cart_item">
                                                            <td className="cart-product-name fw-bold text-dark"> Member <strong className="product-quantity"> : </strong></td>
                                                            <td className="cart-product-total">
                                                                <div className='d-flex justify-content-between align-items-start gap-3'>
                                                                    <div>
                                                                        <span className="amount fw-bold text-dark d-block mb-2">{userInfo.selectedMember.MemberName}</span>
                                                                        <span className="amount">( {userInfo.selectedMember.GenderDesc}, {userInfo.selectedMember.Age} Yrs )</span>
                                                                    </div>
                                                                    <h6 onClick={() => modalAction('MEMBER_MODAL', true, {editId: userInfo.selectedMember.MemberId})} className='mb-0 fw-bold' style={{color: '#007bff', cursor: 'pointer', borderBottom: '2px solid #007fff', fontSize: '1em'}}>
                                                                        Details&nbsp;<i className='bx bx-caret-down' style={{color: '#007bff', fontSize: '1.9rem', verticalAlign: 'middle'}}></i>
                                                                    </h6>
                                                                </div>
                                                            </td>   
                                                        </tr> */}
                                                        <tr className="cart_item">
                                                            <td className="cart-product-name"> Phone <strong className="product-quantity"> : </strong></td>
                                                            <td className="cart-product-total"><span className="amount">{userInfo.RegMob1}</span></td>
                                                        </tr>
                                                        <tr className="cart_item">
                                                            <td className="cart-product-name"> Address <strong className="product-quantity"> : </strong></td>
                                                            <td className="cart-product-total"><span className="amount">{userInfo.Address2}, {userInfo.Address}, {userInfo.City}, {userInfo.StateName}</span></td>
                                                        </tr>
                                                        <tr className="cart_item">
                                                            <td className="cart-product-name"> Pin Code <strong className="product-quantity"> : </strong></td>
                                                            <td className="cart-product-total">
                                                                <span className="amount">{userInfo.Pin}</span>
                                                                {/* {!isDeliverable && <p className='text-danger mb-0' role='button'>We do not deliver to this address. <span className='text-primary text-decoration-underline' onClick={() => setLocationModalActive(true)}>Change Delivery Address</span></p>} */}
                                                            </td>
                                                        </tr>
                                                        <tr className="cart_item">
                                                            <td className="cart-product-name"> E-mail <strong className="product-quantity"> : </strong></td>
                                                            <td className="cart-product-total"><span className="amount">{userInfo.Email}</span></td>
                                                        </tr>
                                                        <tr className="cart_item">
                                                            <td className="cart-product-total" colSpan={2}>
                                                                <Link to='#' onClick={() => {modalAction('EDIT_USER_MODAL', true)}} className='add_an_item_btn ms-auto'>EDIT</Link>
                                                            </td>                          
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* {!selectedServiceLocation.PIN && <h3 className='d-flex justify-content-between align-items-center mt-4'>Service Location <span className='add_an_item_btn m-0' role='button' onClick={() => setLocationModalActive(true)}>SELECT</span></h3>} */}
                                        </div>
                                    )
                                }
                            })()}
                            {selectedServiceLocation.PIN && <div className='row'>
                                <div className="col-md-12">
                                    <h6 className='my-4'>Selected Service Location</h6>
                                    <div className="card-1 location-card mb-4" style={{fontSize: '1.2em'}}>
                                        <div style={{display: 'flex', gap: '1em'}}>
                                            <i className='bx bxs-shopping-bag' style={{fontSize: '3.5em', color: 'var(--clr-1)'}}></i>
                                            <div>
                                                <h5 style={{color: 'var(--clr-1)'}}>{selectedServiceLocation.LocationName}</h5>
                                                <h6>{selectedServiceLocation.Address}</h6>
                                                <p>{selectedServiceLocation.PIN}</p>
                                            </div>
                                        </div>
                                        {/* <i className='bx bx-check-circle' style={{fontSize: '2em', color: 'var(--bg-1)'}}></i> */}
                                        {/* <button className="controlled-btn ms-auto" type="button" onClick={() => setLocationModalActive(true)}>CHANGE</button>    */}
                                    </div>
                                </div>
                            </div>}
                        </div>}
                    </div>
                    <div className="col-lg-7 col-12">
                        {isLoggedIn && <div className="your-order">
                            <h3>Your order</h3>
                            <div className="your-order-table table-responsive" style={{borderBottom: '3px solid #00ddd4', marginBottom: '2.5rem'}}>
                                    
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="cart-product-name">{hasTable ? 'NEW ORDERED ITEMS' : 'Product'}</th>
                                            <th className="cart-product-name text-center">QTY</th>  
                                            <th className="cart-product-name text-end">{hasTable ? 'RATE' : 'MRP'}</th>
                                            {hasTable || <th className="cart-product-name text-end">Discount</th>}
                                            <th className="cart-product-total text-end">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartArray.length ? '' : <tr className="cart_item bg-white">
                                            <td className="cart-product-name" colSpan={hasTable ? 4 : 5}><strong className="product-quantity">NO ITEM IS ADDED.</strong></td>
                                        </tr>}
                                        {cartArray.map((item) => (
                                            <tr key={item.ItemId} className="cart_item bg-white">
                                                <td className="cart-product-name"> {item.Description}</td>
                                                <td className="cart-product-name">
                                                    {/* <strong className="product-quantity">{item.count}</strong> */}
                                                    <div className='d-flex justify-content-between align-items-center' style={{color: '#898989'}}>
                                                        <i className='bx bx-minus-circle' onClick={() => {if (item.count !== 1) cartAction('ADD_ITEM', {...item, count: item.count - 1}, 'pharmacy')}} style={{fontSize: '1.2em', verticalAlign: 'middle'}}></i>
                                                            <span className='text-dark'>{item.count}</span>
                                                        <i className='bx bx-plus-circle' onClick={() => cartAction('ADD_ITEM', {...item, count: item.count + 1}, 'pharmacy')} style={{fontSize: '1.2em', verticalAlign: 'middle'}}></i> 
                                                    </div>
                                                </td>
                                                {hasTable ? 
                                                    <td className="cart-product-name text-end"><strong className="product-quantity">₹ {item.SRate}</strong></td>
                                                : 
                                                    <>
                                                        <td className="cart-product-name text-end"><strong className="product-quantity">₹ {item.ItemMRP}</strong></td>
                                                        <td className="cart-product-name text-end"><strong className="product-quantity"> {item.DiscountPer}%</strong></td>
                                                    </>
                                                }
                                                <td className="cart-product-total text-end text-nowrap">₹ {parseFloat(item.SRate) * parseFloat(item.count)}<span className="amount" onClick={() => cartAction('REMOVE_ITEM', item.ItemId, 'pharmacy')}> <i class='bx bx-x-circle ms-2 text-danger circle-rounded' role='button' style={{fontSize: '1.3em', verticalAlign: 'sub'}}></i></span></td>
                                            </tr>
                                        ))}
                                       {hasTable && <tr className="cart_item bg-white" style={{borderTop: '2px solid #c1c1c1'}}>
                                            <th className="py-3">Current Total</th>
                                            <th className="py-3"></th>
                                            {hasTable || <th className="py-3"></th>}
                                            <th className="py-3"></th>
                                            <th className="py-3 text-end">₹ {cartSubtotal}</th>
                                        </tr>}
                                    </tbody>
                                    <tfoot>
                                        {hasTable || <tr className="cart-subtotal">
                                            <th>Cart Subtotal</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><span className="amount">₹ {cartSubtotal}</span></th>
                                        </tr>}
                                        {hasTable || <tr className="cart-subtotal">
                                            <th>Service Charge</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><span className="amount">₹ 00</span></th>
                                        </tr>}
                                        {hasTable || <tr className="order-total">
                                            <th>Order Total</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><strong><span className="amount">₹ {cartSubtotal}</span></strong></th>
                                        </tr>}
                                    </tfoot>
                                </table>

                                {restaurantTable?.ProvInvBillid ? <table className="table mt-4 mb-0" style={{display: viewItems ? 'table' : 'none', transition: '0.4s linear', borderTop: '3px solid rgb(0, 221, 212)'}}>
                                    <thead>
                                        <tr>
                                            <th className="cart-product-name">Previously Ordered Items</th>
                                            <th className="cart-product-name">QTY</th>                                            
                                            <th className="cart-product-name text-end">RATE</th>
                                            <th className="cart-product-total text-end">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {previousOrder.data.SalesObj.SalesDetails?.map((item, index) => (
                                            <tr key={index} className="cart_item bg-white">
                                                <td className="cart-product-name"> {item.Description}</td>
                                                <td className="cart-product-name"><strong className="product-quantity">{item.BillQty}</strong></td>
                                                <td className="cart-product-name text-end"><strong className="product-quantity">₹ {item.Rate}</strong></td>
                                                <td className="cart-product-total text-end text-nowrap">₹ {parseFloat(item.Rate) * parseFloat(item.BillQty)}<span className="amount"> </span></td>
                                            </tr>
                                        ))}
                                         <tr className="cart_item bg-white" style={{borderTop: '2px solid #c1c1c1'}}>
                                            <th className="py-3">Previous Total</th>
                                            <th className="py-3"></th>
                                            <th className="py-3"></th>
                                            <th className="py-3 text-end">₹ {previousOrder.data.SalesObj.Amount}</th>
                                        </tr>
                                        <tr className="order-total">
                                            <th>Order Total</th>
                                            <td></td>
                                            <td></td>
                                            <th className='text-end'><strong><span className="amount">₹ {cartSubtotal + previousTotal}</span></strong></th>
                                        </tr>
                                    </tbody>
                                </table> : ''}

                                {prescription.patient.name && <div className='d-flex justify-content-between align-items-start' style={{borderTop: '1px solid #d3d3d3', padding: '1em 0'}}>
                                    <h6 style={{marginTop: 3}}>Patient Name</h6>
                                    <div className='d-flex flex-column align-items-end'>

                                        <div className='hover-dropdown'>
                                            <h6 className='mb-0 d-flex align-items-center gap-2 mb-1' style={{fontWeight: 500}}>
                                                <i className='bx bx-check-double' style={{fontSize: '1.7em', verticalAlign: 'middle', color: 'var(--clr-9)'}}></i> 
                                                <span>
                                                    {prescription.patient.name} &nbsp;&nbsp;
                                                    <i className='bx bxs-down-arrow' style={{color: '#007bff', fontSize: '1.9rem', verticalAlign: 'middle'}}></i>
                                                </span>
                                            </h6>
                                            <ul className="dropdown-menu" style={{fontSize: '1.05em'}}>
                                                <li><Link onClick={() => modalAction('PRESCRIPTION_MODAL', true)} className="dropdown-item py-3" to="#"><i className='bx bx-user-circle me-2' style={{'--clr': '#0494f9'}}></i> View Details</Link></li>
                                                {/* <li><Link onClick={() => modalAction('MEMBER_SELECT_MODAL', true)} className="dropdown-item py-3" to="#"><i className='bx bx-calendar-check me-2' style={{'--clr': '#0494f9'}}></i> Change Member</Link></li> */}
                                            </ul>
                                        </div>
                                         <span className="amount fw-normal">( {prescription.patient.gender.GenderDesc}, {prescription.patient.age} Yrs )</span>  
                                    </div>
                                </div>}

                                {/* <h6 onClick={() => modalAction('MEMBER_SELECT_MODAL', true)} className='mb-0 fw-bold' style={{color: '#007bff', cursor: 'pointer', borderBottom: '2px solid #007fff', fontSize: '0.9em'}}>
                                    <i className='bx bx-transfer-alt' style={{color: '#007bff', fontSize: '1.9rem', verticalAlign: 'middle'}}></i>&nbsp;CHANGE
                                </h6> */}
                                {/* <h6 onClick={() => modalAction('MEMBER_MODAL', true, {editId: userInfo.selectedMember.MemberId})} className='mb-0 fw-bold' style={{color: '#007bff', cursor: 'pointer', borderBottom: '2px solid #007fff', fontSize: '1em'}}>
                                    
                                </h6> */}

                                {hasTable ? '' : <>
                                    {prescription.imgName ? 
                                        <div className='d-flex justify-content-between align-items-center' style={{borderTop: '1px solid #d3d3d3', padding: '1em 0 1em'}}>
                                            <h6 className='mb-0' style={{color: '#005feb'}}>YOUR PRESCRIPTION</h6>
                                            <div className='d-flex flex-column flex-md-row gap-4'>
                                                <div className='img-preview'>
                                                    <div className="zoom-img">
                                                        <i className='bx bx-search search'></i>
                                                    </div>
                                                    <i className='bx bx-x delete' onClick={() => globalDataAction({ prescription: { patient: { docName: '', docAddress: '' } } })}></i>
                                                    <a className="popup-img venobox vbox-item d-flex justify-content-center" href={prescription.src} data-gall="myGallery">
                                                        <img src={prescription.src} alt="Uploaded content"/>
                                                    </a>
                                                </div>
                                                <h5 className='mb-0 text-end' style={{fontFamily: 'Lato', fontSize: '1em'}}>{prescription.imgName}</h5>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className='d-flex justify-content-between align-items-center' style={{borderTop: '1px solid #d3d3d3', padding: '1em 0 1em'}}>
                                                <h6 className='text-danger mb-0'>ADD PRESCRIPTION</h6>
                                                <h6 className='mb-0' onClick={() => modalAction('PRESCRIPTION_MODAL', true)} style={{color: '#007bff', cursor: 'pointer'}}><i className='bx bx-file' style={{fontSize: '3rem', verticalAlign: 'middle', marginBottom: '0.4rem'}}></i> UPLOAD</h6>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center' style={{borderTop: '1px solid #d3d3d3', padding: '1em 0 0.1em'}}>
                                                <h6>Payment Method</h6>
                                                <h6><i className='bx bxs-credit-card' style={{fontSize: '3rem', verticalAlign: 'middle', color: '#00BCD4', marginBottom: '0.4rem'}}></i> Cash on Delivery</h6>
                                            </div>
                                        </>
                                    }
                                </>}
                            </div>
                            {/* <div className='coupon-accordion'>
                                <h3 onClick={() => setCouponTab(!couponTab)}>Have a coupon? <span id="showcoupon">Click here to enter your code</span></h3>
                                {couponTab && <div id="checkout_coupon" className="coupon-checkout-content">
                                    <div className="coupon-info">
                                        <form action="#">
                                            <p className="checkout-coupon">
                                                <input placeholder="Coupon code" type="text" onChange={dummyFunction}/>
                                                <input value="Apply Coupon" type="submit" onChange={dummyFunction}/>
                                            </p>
                                        </form>
                                    </div>
                                </div>}
                            </div> */}
                            <div className="payment-method">
                                {/* <h4>Payment Method</h4> */}
                                <div className="payment-accordion">
                                    {/* <div id="accordion">
                                        <div className="card shadow-none">
                                            <div className="card-header" id="#payment-3">
                                                <h5 className="panel-title">
                                                    <Link to='#' className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <i className='bx bxs-badge-check' style={{fontSize: '3rem', verticalAlign: 'middle', color: '#00BCD4', marginBottom: '0.4rem'}}></i> Cash on Delivery
                                                    </Link>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" className="collapse" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="order-button-payment">
                                        {hasTable ? '' : <>
                                            {!prescription.src && <p className='text-dark mb-2' style={{fontFamily: 'Lato', fontWeight: 600}}><i className='bx bxs-info-square me-2 text-danger' style={{fontSize: '1.5em', verticalAlign: 'sub'}}></i>Please Attach your prescription to place an order.</p>}
                                        </>}
                                        { compCode === TAKE_HOME_ID ?
                                            <button type='submit' className={`ms-auto ${(isLoggedIn && isDeliverable && prescription.src) ? '' : 'pe-none opacity-50 bg-secondary'}`} onClick={placeOrder}>Place Order</button> :    
                                            <button type='submit' className={`ms-auto ${(isLoggedIn && isDeliverable) ? '' : 'pe-none opacity-50 bg-secondary'}`} onClick={placeOrder}>Place Order</button>     
                                        }
                                    </div>                                               
                                    {isDeliverable ? '' : <div className='row mt-4'>    
                                        <div className="col-md-12">
                                            <div className="checkout-form-list position-relative">
                                                <p className='text-danger mb-0 mt-2'>Now we have no service at your PIN code - {userInfo.Pin} We will be available in your area very soon.</p>
                                            </div>
                                        </div>
                                        <div className="cta-no-location your-order-table user-details table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th className="cart-product-name"><i className='bx bxs-info-circle' style={{fontSize: '1.85rem', color: 'orange'}}></i> What you can do !</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="cart_item">
                                                        <td className="cart-product-name">Change your address <button onClick={() => {closeModal();modalAction('EDIT_USER_MODAL', true);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Address</button></td>
                                                    </tr>
                                                    <tr className="cart_item">
                                                        <td className="cart-product-name">Change the area <button onClick={() => {closeModal();focusArea(globalDataAction);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Area</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        <ModalComponent 
            isActive={locationModalActive} 
            className={'location-selection-modal'} 
            child={<LocationModal closeModal={closeModal} userInfo={userInfo}  compCode={compCode} globalData={globalData} globalDataAction={globalDataAction} modalAction={modalAction} setDeliverable={setDeliverable} />}
        />
    </>
  )
}

const mapStateToCheckout = (state) => {
  return { compCode: state.compCode, cart: state.cart, isLoggedIn: state.isLoggedIn, userInfo: state.userInfo, modals: state.modals, globalData: state.globalData };
}

export default connect(mapStateToCheckout, {breadCrumbAction, loginStatusAction, userInfoAction, loaderAction, modalAction, cartAction, myOrdersAction, globalDataAction})(Checkout);


function LocationModal({ closeModal, compCode, userInfo, globalData, setDeliverable, globalDataAction, modalAction }) {

    const locationId = globalData.location.LocationId;
    const [location, setLocation] = useState({ Pin: '' });
    const [locationList, setLocationList] = useState({loading: true, data: '', err: {status: false, msg: ''}});
    
    useEffect(() => {       
        if (!location.Pin.length) {
            return;
        } else if (location.Pin.length < 6) {
            alert('Your Pin Code is Invalid Please change your Pin Code.');
            closeModal();
            return;
        }
        if (!locationId) return; 
        const getServiceLocations = async () => {
            const res = await getFrom(`${process.env.REACT_APP_BASE_URL}/api/Location/Get?CID=${compCode}&LocationId=${locationId}&PinCode=${location.Pin}`, {}, setLocationList);            // using useCallback to avoid esling warning about useEffect dependencies.
            if (res) {
                setLocationList(res);   
            }
        }
        setTimeout(() => {
            getServiceLocations();
        }, 1000);
    }, [location.Pin, locationId])

    useEffect(() => {
        setLocation({ Pin: userInfo.Pin })
    }, [])

    const renderLocationList = (data) => {
        if (data.loading) {
          return <Spinner min_height='19rem' fSize='1.5rem'/>;
        } else if (data.err.status) {
          return <div className='text-center my-5'><h2 className="text-danger mark">An error occured, please try again later. Error code: <span className='text-dark'>{data.err.msg}</span></h2></div>;
        } else if (data.data === 0) {
          setDeliverable(false);
          return <p className='text-danger mb-0 mt-2'>Now we have no service in this PIN - We will be available in your area very soon.</p>;
        } else {
          closeModal();
          setDeliverable(true);
          return;
        }
    }    

    return (
        <form>
            <div className='card'>
                <h5 className="card-header d-flex justify-content-between" style={{padding: '0.7em 1em'}}>Please Select a Service Location <i className='bx bx-x-circle' onClick={closeModal} role='button' style={{fontSize: '1.2em'}}></i></h5>
                <div className='card-body' style={{padding: '1.3em'}}>
                    <div className='row'>
                        <div className="col-md-12">
                            <div className="checkout-form-list position-relative">
                                <label>Pincode / Zip <span className="required">*</span></label>
                                <input readOnly type="text" name='Pin' value={location.Pin} onChange={(e) => {handleNumberInputs(e, setLocation);}} autoComplete='off' maxLength='6'/>
                                {renderLocationList(locationList)}
                            </div>
                        </div>
                        <div className="cta-no-location your-order-table user-details table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="cart-product-name"><i className='bx bxs-info-circle' style={{fontSize: '1.85rem', color: 'orange'}}></i> What you can do !</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="cart_item">
                                        <td className="cart-product-name">Change your address <button onClick={() => {closeModal();modalAction('EDIT_USER_MODAL', true);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Address</button></td>
                                    </tr>
                                    <tr className="cart_item">
                                        <td className="cart-product-name">Change the area <button onClick={() => {closeModal();focusArea(globalDataAction);}} className="controlled-btn ms-auto" type="button" style={{fontSize: '1.3rem'}}>Change Area</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}