import { HashRouter, Route, Switch } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// TAKEHOME--------------------------------------------------------------------------------
import './CSS/ePharma/nice-select.css';
import './CSS/ePharma/helper.css';


import './CSS/default/animations.css';
import './CSS/default/global.css';
import './CSS/default/mobileViewStyles.css';
import './CSS/default/global-responsive.css';
import './CSS/default/react-datepicker.css';
import './CSS/default/fontawesome/css/fontawesome.min.css';
import './CSS/default/fontawesome/css/all.min.css';
import './CSS/default/fancyboxNew.css';
import './CSS/default/slick.css';
import './CSS/default/venobox.css';
import './CSS/default/icofont/icofont.min.css';


import Home from './companiens/default/home.jsx';
import Specialists from './companiens/default/specialists.jsx';
import Profile from './companiens/default/profiles/index.jsx';
import { connect } from 'react-redux';

import { ConnectedIsMobile, ConnectedToast, NotFound, useStylesheet, useOnlineStatus, WifiLoader, ScrollToTop, CacheBusting } from './companiens/default/utilities.jsx';
import { useState } from 'react';
import { useEffect } from 'react';
import CommonModals from './companiens/common/modals/index.jsx';
import HeaderRoute, { 
    FooterRoute, HomeRoute, BottomNavRoute, BedStatusRoute, InvoicePrintRoute, LabTestPageRoute, PatientQueueRoute, PharmacyRoute, PrescriptionRoute,
    MyOrdersRoute, ServicesRoute, ProductRoute, CartRoute, DashboardsRoute, AboutUsRoute, DoctorsRoute, FAQRoute, TestimonialsRoute, ServiceRoute, ContactUsRoute, 
    CareersRoute, BlogsRoute, BlogRoute, DoctorRoute, BedCategoriesRoute, SocialWorksRoute, HealthCenterRoute, PrivacyPolicyRoute, ReturnPolicyRoute, TermsConditionRoute,    
    WishlistRoute,
    CheckoutRoute,
    ModalsRoute,
    HealthPakagesRoute,
    HealthPakageRoute
} from './Routes/Route.jsx';

import CssRoute from './Routes/CssRoute.jsx';
import Auth from './companiens/default/auth.jsx';
import InitHeader from './companiens/common/initHeader';
import Articles from './companiens/bsn/Articles.jsx';
import { ToastContainer } from 'react-toastify';
// import Checkout from './companiens/default/checkout.jsx';
// import wishlist from './companiens/default/wishlist.jsx';
// import Invoices from './companiens/default/prints/invoices/index.jsx';
import FilterPage from './companiens/default/filterPage.jsx';
import Franchisee from './companiens/ePharma/franchisee.jsx';
import { ConnectedLogout } from './companiens/ePharma/utilities.jsx';
import { hasTable, RAJE_RESTAURANT_ID } from '../constants.js';

function App({ isLoading, compCode }) {

  const [stylePath, setStylePath] = useState('');

  useEffect(() => {
    if (compCode === 'MjLxadrssyExUU7EojuDtw==') {
      setStylePath('css/am-nursing/styles.css');
    }
  },[compCode, stylePath])

  // useScript('js/bootstrap.min.js');
  useStylesheet(stylePath); 

  // 7000000000
  // 7000000001
  // 7000000002
  // 7003523423
  // takehome admin id THADM@111 2444

  // 7044269898
  // Pablo#468

  // 9330241456	- sameer takehome user
  // password: 8583814626

  // Mongo Cluster 
  // username - rk@mongodb
  // pw - NFmQh89Jx8rpobYG
  // admin-rahul pw - OmMmBHSDSLZS1eiA for database connection.

  return (
    <div className='app-container'>
      <HashRouter>
        {/* {isHeaderActive && <SiteHeader isLoading={isLoading} />} */}
        {/* <div className={!isHeaderActive ? 'hide' : ''}> */}
          <SiteHeader isLoading={isLoading} />
        {/* </div> */}
        <div className='app-content'>
          <Switch>
            <Route path='/home' component={Home}/>
            <Route path='/specialists' component={Specialists}/>
            <Route path='/' exact component={HomeRoute}/>
            <Route path='/pharmacy' component={PharmacyRoute}/>
            <Route path='/labTests' component={LabTestPageRoute}/>
            <Route path='/healthPakages/:id' component={HealthPakageRoute}/>
            <Route path='/healthPakages' component={HealthPakagesRoute}/>
            <Route path='/cartPage' component={CartRoute}/>
            <Route path='/wishlist' component={WishlistRoute}/>
            <Route path='/dashboard' component={DashboardsRoute}/>
            <Route path='/productPage/:id' component={ProductRoute}/>
            <Route path='/myOrders' component={MyOrdersRoute}/>
            <Route path='/services/:service' component={ServiceRoute}/> 
            <Route path='/services' component={ServicesRoute}/>
            <Route path='/patientQueue' component={PatientQueueRoute}/>
            <Route path='/prescription/:id' component={PrescriptionRoute}/>
            <Route path='/invoices/:id' component={InvoicePrintRoute}/>
            <Route path='/bedStatus' component={BedStatusRoute}/>
            <Route path='/profile/:id?' component={Profile}/> 
            <Route path='/aboutUs' component={AboutUsRoute}/> 
            <Route path='/contactUs' component={ContactUsRoute}/> 
            {/* <Route path='/doctorProfile/:id' component={DoctorProfile}/> */}
            <Route path='/doctors/:id' component={DoctorRoute}/> 
            <Route path='/doctors' component={DoctorsRoute}/> 
            <Route path='/faq' component={FAQRoute}/> 
            <Route path='/careers' component={CareersRoute}/> 
            <Route path='/blogs/:id' component={BlogRoute}/> 
            <Route path='/blogs' component={BlogsRoute}/> 
            <Route path='/testimonials' component={TestimonialsRoute}/> 
            <Route path='/bedCategories' component={BedCategoriesRoute}/> 
            <Route path='/socialWorks' component={SocialWorksRoute}/> 
            <Route path='/healthCenter' component={HealthCenterRoute}/> 
            <Route path='/articles' component={Articles}/>
            <Route path='/checkout' component={CheckoutRoute}/>
            <Route path='/filters' component={FilterPage}/>

            <Route path='/privacyPolicy' component={PrivacyPolicyRoute}/>
            <Route path='/returnPolicy' component={ReturnPolicyRoute}/>
            <Route path='/termsConditions' component={TermsConditionRoute}/>
            <Route path='/franchisee' component={Franchisee}/>
            {/* <Route path='/logout' component={ConnectedLogout}/> */}
            <Route path='/*' component={NotFound}/>
          </Switch>
        </div>
        {/* {isHeaderActive && <SiteFooter/>} */}
        {/* <div className={!isHeaderActive ? 'hide' : ''}> */}
          <SiteFooter/>
        {/* </div> */}
      </HashRouter>
      <style>
        {
         hasTable && `
          .filter-view > div {
            max-width: 12em !important;
            margin-top: 1em !important;
          }

          .product-card-1 .img-box {
            height: 11em;
          }
          .checkout-area h3 {
            font-size: 1.9rem;
            margin-bottom: 1.1rem !important;
          }
          .your-order-table table th, .your-order-table table td {
            padding: 0.5em 6px;
          }
        `}
      </style>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { isLoading: state.isLoading, compCode: state.compCode, isHeaderActive: state.isHeaderActive, userInfo: state.userInfo, state: state };
}

export default connect(mapStateToProps, {})(App);

const SiteHeader = ({ isLoading }) => {
  const isOnline = useOnlineStatus();
  return (
    <>
      <CacheBusting />
      {isLoading ? <div className='spinner-container'><GlobalLoader/></div> : ''}
      {!isOnline && <WifiLoader/>}
      <CssRoute />
      <HeaderRoute />
      <InitHeader />
      <Auth/>
      <BottomNavRoute/>
      <ScrollToTop/>
    </>
  )
}

const SiteFooter = () => {
  return (
    <>
      <FooterRoute/>
      <ModalsRoute />
      <CommonModals />
      <ConnectedToast/>
      <ConnectedIsMobile/>
      <ToastContainer className="my-toast" />    
    </>
  )
}


export const GlobalLoader = () => {
  return (
    <div className='spinner-box' style={{minHeight: '100vh'}}>
      <div className="wrapper">
        <div className="circle"></div>     
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
        <div className="shadow"></div>
      </div>
    </div> 
  )
}



// TAG#
// itemMaster