import React, { useState, useEffect, useCallback, useRef } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { connect } from 'react-redux';
import { toastAction, breadCrumbAction, isMobileAction, modalAction, globalDataAction, loaderAction, userInfoAction, cartAction, wishlistAction, bookingInfoAction, loginStatusAction, resetUserAction } from '../../../actions';


import Carousel from 'react-bootstrap/Carousel';
// import history from '../history';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useHistory } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import axios from 'axios';
import store from './../../../index.js';
import { createPortal } from 'react-dom';
import Alert from 'react-bootstrap/Alert';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import { defaultId, ePharmaId, hasTable, TAKE_HOME_ID, XYZ_ID } from '../../../constants.js';
// import { ToastContainer as ReactToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { initAppState } from '../../../reducers/appState.js';

const useScript = url => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;

// export const useFavicon = url => {
//   useEffect(() => {
//     const el = document.createElement('link');
//     el.rel = 'icon';
//     el.href = url;
//     el.async = true;
//     document.head.appendChild(el);
//     return () => {
//       document.head.removeChild(el);
//     };
//   }, [url]);
// };

export const UseFavicon = ({ compCode }) => {
  useEffect(() => {
    let companies = {
      [ePharmaId]: 'epharma.png',
      [TAKE_HOME_ID]: 'takeHome.png',
      [XYZ_ID]: 'XYZ.png'
    }
    const el = document.createElement('link');
    el.rel = 'icon';
    el.href = `/images/favicons/${companies[compCode]}`;
    el.async = true;
    document.head.appendChild(el);
    return () => {
      document.head.removeChild(el);
    };
  }, [compCode]);
  return;
};

export const NologinWarning = () => {
  return (
    <div className="modal fade show d-block" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{background: '#bdbdbd'}}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-danger fw-bold" id="exampleModalLabel">Warning !</h5>
            <Link className="btn-close" to='/' aria-label="Close" ></Link>
          </div>
          <div className="modal-body">
            You are not Logged in Please log in to view this page.
          </div>
          <div className="modal-footer">
            <Link to='/' className="btn btn-primary" data-dismiss="modal">GO TO HOMEPAGE</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export const handleNumberInputs = (e, setStateName) => {
  const {name, value} = e.target;
  const re = /^[0-9\b]+$/;
  if (value === '' || re.test(value)) {
   setStateName(preValue => {
       return {...preValue, [name]: value};
     });
  }
}

export const useFetch = (url, compCode) => {

  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    if (compCode) {
      try {
        const res = await fetch(url);
        if (res.status === 500) {            // Status 500 called internal server error is not an error it's a responce.
          setError(true);                    // hence it can't be catched by try catch statement hence handling it mannually.
          return;
        }
        const json = await res.json();
        setData(json);          
      } catch (err) {
        setError(err);
      }
      setLoading(false);
    }

  }, [url, compCode]);

  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {                        // turn on Timeout to test Skeleton loading.
      fetchData();
    // }, 5000);
  }, [fetchData]);

  return [data, isLoading, error]
}



export const getCurrentDate = () => {
  const d = new Date();
  const currentDate = d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
  return currentDate;
}

const IsMobile = ({ isMobileAction }) => {                                             // Determines if device is mobile or desktop.
  // const [isMobile, seIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 500px)');
    isMobileAction(mediaQuery.matches);
    const handleMediaQueryChange = (e) => {
      isMobileAction(e.matches);
    }
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    }
  }, [isMobileAction])

  return;
}

const mapStateToIsMobile = (state) => {
  return {};
}
export const ConnectedIsMobile = connect(mapStateToIsMobile, {isMobileAction})(IsMobile);

const ShowToast = ({ isToastActive, toastAction, isMobile }) => {
    // const [isMobile, seIsMobile] = useState(false);

    // useEffect(() => {
    //   const mediaQuery = window.matchMedia('(max-width: 500px)');
    //   seIsMobile(mediaQuery.matches);
    //   const handleMediaQueryChange = (e) => {
    //     seIsMobile(e.matches);
    //   }
    //   mediaQuery.addEventListener('change', handleMediaQueryChange);
    //   return () => {
    //     mediaQuery.removeEventListener('change', handleMediaQueryChange);
    //   }
    // }, [])

    return (
      <div aria-live="polite" aria-atomic="true" className="toastBackground" style={{position: 'fixed', top: '0', left: '0', height: '100vh', width: '100vw', pointerEvents: 'none'}}>
        <ToastContainer className="p-3" position={isMobile ? 'bottom-center' : 'middle-end'}>
          <Toast onClose={() => toastAction(false, {})} show={isToastActive.status} delay={300000} autohide>
            <Toast.Header>
              <img src="favicon.PNG" className="rounded me-3" alt="asdf" style={{height: '2.5rem'}}/>
              <strong className="me-auto">{isToastActive.msg}</strong>
              <small>Just now</small>
            </Toast.Header>
            <Toast.Body>
              <div className="w-100 d-flex justify-content-between">
                <p className="mb-0 fw-bold">{isToastActive.item.Description}</p>
                <p className="mb-0 fw-bold mark bg-success rounded-pill px-4">₹ {isToastActive.item.SRate}</p>
              </div>
              <Link to={'/cartPage'} className='controlled-btn mt-2' style={{display: 'inline-block', fontFamily: 'Poppins', padding: '0.4em 1.4em 0.3em'}}>Visit Cart</Link>
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    );
}

const mapStateToProps2 = (state) => {
  return { isToastActive: state.isToastActive, isMobile: state.isMobile };
}

export const ConnectedToast = connect(mapStateToProps2, {toastAction})(ShowToast);

export const ProductToastCard = ({ toastData, closeToast }) => {
  return (
    <div className="toast fade show">
      <div className="toast-header" style={{color: 'var(--clr-1)'}}>
        <i className='bx bxs-smile me-2' style={{fontSize: '1.4em'}}></i>
        <strong className="me-auto">{toastData.msg}</strong><small className='text-muted'>Just now</small>
        <button type="button" onClick={closeToast} className="btn-close"></button>
      </div>
      <div className="toast-body">
        <div className="w-100 d-flex justify-content-between">
          <p className="mb-0 fw-bold text-dark">{toastData.product.name}</p>
          <p className="mb-0 fw-bold mark bg-success rounded-pill px-4">₹ {toastData.product.price}</p>
        </div>
        <Link className="controlled-btn mt-2" onClick={closeToast} to={toastData.button.link} style={{display: 'inline-block', fontFamily: 'Poppins', padding: '0.4em 1.4em 0.3em'}}>{toastData.button.text}</Link>
      </div>
    </div>
  )
}

export const productToast = (productToastData, options) => {
  if (hasTable) return;
  return toast(<ProductToastCard toastData={productToastData} />, { position: "top-right", autoClose: 2500, closeButton: false, className: 'product-toast', ...options });
};
export const stringToast = (toastData, type='') => toast(toastData, { type: type, autoClose: 2000 });

export const Notice = ({ toastData, closeToast }) => {
  return (
    <div className='d-flex align-items-center gap-4'>
      <i className='bx bxs-info-circle' style={{fontSize: '3em', color: 'orangered'}}></i>
      <div>
        <h4 style={{color: 'orangered'}}>{toastData.title}</h4>
        <span>{toastData.msg}</span>
      </div>
    </div>
  )
}

export const noticeToast = (noticeData, options) => toast(<Notice toastData={noticeData} />, options); 

export const ControlledCarousel = ({ data, interval, controls }) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} interval={interval} controls={controls}>
        {
            data.map((item, index) => {
              return (
                <Carousel.Item key={index}>
                    <img className="w-100" src={item} alt={`${index + 1}_slide`}/>
                    {/* <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
              )
            })
        }
    </Carousel>
  );
}


export const ControlledTabs = ({ children, data, activetab }) => {
  const [key, setKey] = useState(activetab);

  return (
    <Tabs
      id="date-slot-tab"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {
        data.map((item, index) => {
          return (
            <Tab eventKey={item.name.sName} key={index} title={item.name.sName}>
              {React.cloneElement(children, { data: item.name, key: index })}
            </Tab>
          );
        })
      }
    </Tabs>
  );
}


export const makeAppointment = (isLoggedIn, action, status, mode, history) => {
  if (isLoggedIn) {
    history.push('/specialists');
  } else {
    action(status, mode);
  }
}

export const useDocumentTitle = (title, prevailOnUnmount = false) => {      // To Dynamicall set the website Title.
  const defaultTitle = useRef(document.title);                              // Used in header page.
  // const favIcon = useRef(document.);                                     // Used in header page.

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [prevailOnUnmount])
}


export const ModalComponent = ({ isActive, child, className }) => {

  return (
    <Modal show={isActive} centered backdrop="static" className={`${className} epharma-global`} keyboard={false}>                     {/* modals are beigng directly controlled by redux states. */}
      <Modal.Body>
        {child}
      </Modal.Body>
    </Modal>
  )
}

// Remove empty, nil, undefined values from objects.
// _.omitBy({ a: null, b: 1, c: undefined, d: false }, _.isNil)
// let o = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== '' || v !== null));

export const CustomModal = ({ isActive, heading, child, handleClose, modalName }) => {

  return (
    <div className={`custom-modal-backdrop ${isActive ? 'active' : ''}`}>
        <div className='custom-modal'>
        <i className='bx bx-x-circle close-custom-modal' onClick={() => handleClose(modalName, false)}></i>
            {child}
        </div>
    </div>
  )
}

export const DropdownElement = ({ title, variant, data }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant={variant} id="dropdown-basic">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {data.map((item, index) => {
          return (
            <Dropdown.Item as="button">{item.text}</Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export const customTabsButtons = (data, activeItem, onClickHandler) => {
  return data.map((item, index) => {
    return (
      <label className={`custom_check ${activeItem === item ? 'active' : ''}`} key={index}>
        <input type="button" name="tab-input" onClick={() => onClickHandler(item)}/>
        As {item}
      </label>
    )
  })
}


// export const logOut = (loginAction, userAction) => {
//     localStorage.removeItem('userLoginData');
//     loginAction(false);
//     userAction({
//       Salutation: '',
//       Name: '',
//       // EncCompanyId: '',
//       PartyCode: '',
//       RegMob1: '',
//       Email: '',
//       Gender: '',
//       Address: '',
//       Age: '',
//       AgeMonth: '',
//       AgeDay: '',
//       UserPassword: '',
//       UnderDoctId: null,
//       Department: {dName: 'All', SubCode: 0},
//       TimeSlotId: null,
//       UserType: 'Patient',
//       Qualification: '',
//       RegNo: '',
//       SpecialistId: 0,
//       UserId: '',
//       MPartyCode: '',
//       SpecialistDesc: '',
//       selectedCompany: {},
//       Doctor: {}
//     });
//     // history.push('/');
// }

export const handleLogOut = (history) => {
 
  store.dispatch(resetUserAction());
  store.dispatch(bookingInfoAction(initAppState.bookingInfo));
  store.dispatch(cartAction('EMPTY_CART', {}, ''));
  store.dispatch(wishlistAction('EMPTY_WISHLIST', {}, ''));
  store.dispatch(globalDataAction({ 
    scrollPos: { home: '', filterPage: '' },
    prescription: { patient: { docName: '', docAddress: '' } },
  }));
  // store.dispatch({ type: 'EMPTY_CART', payload: initAppState.cart });    // alternative syntax.
  
  localStorage.removeItem('ePharmaUserData');
  localStorage.removeItem('epharmaItemsList');

  store.dispatch(loginStatusAction(false));
  history.push('/');
  store.dispatch(modalAction('ALERT_MODAL', true, 'logout'));
}

export const logOutUser = async (history) => {
  localStorage.removeItem('userLoginData');
  localStorage.removeItem('epharmaItemsList');
  history.push('/');
  store.dispatch(modalAction('ALERT_MODAL', true, 'logout'));
  await wait(2000);
  window.location.reload();
}

const Logout = ({ isLoggedIn, modalAction }) => {
  const history = useHistory();
  useEffect(() => {
    if (!isLoggedIn) return;                      // prevents loop due to reload.
    setTimeout(() => {
      localStorage.removeItem('ePharmaUserData');
      localStorage.removeItem('epharmaItemsList');
      window.location.reload();
    }, 1000);
  },[])

  return (
    <div className="order-success-modal logout-page">
        {isLoggedIn ? <Spinner min_height='10rem' fSize='1.6rem'/> : <i className='bx bx-check'></i>}
        <h3 style={{margin: '1.4em 0 0.4em'}}>
          {isLoggedIn ? 'Please Wait...' : 'You Successfully Logged out !'}
        </h3>
        <p>Thank You, Visit Again !</p>
        <div className="cart-buttons mt-0 justify-content-center">
            <button onClick={() => {history.push('/'); modalAction('LOGIN_MODAL', true)}} className="continue-button border-0">Login Again</button>
            <Link to={'/'} className="continue-button border-0">Back to Home</Link>
        </div>
    </div>
  )
}
const mapStateToLogout = (state) => ({ isLoggedIn: state.isLoggedIn });
export const ConnectedLogout = connect(mapStateToLogout, {modalAction})(Logout);

// export const Spinner = ({ min_height='10rem', fSize='16px', visible='visible' }) => {     
//   let sOpacity = visible === 'visible' ? '1' : '0';
//   let pEvents = visible === 'visible' ? 'auto' : 'none';
//   let zIndex = visible === 'visible' ? '100000' : '-1';          
//   return (
//     <>
//       <div className='spinner-box test' style={{minHeight: min_height, fontSize: fSize, visibility: visible, opacity: sOpacity, pointerEvents: pEvents, zIndex: zIndex}}>
//         <div className="loader"></div>       {/* stripe square and running square */}
//       </div>
//     </>
//   )
// }

export const Spinner = ({ min_height='10rem', fSize='16px' }) => {                
  return (
    <>
      {/* <div className='spinner-box test' style={{minHeight: min_height, fontSize: fSize}}>
        <div className="loader"></div>     
      </div> */}
      <div className='spinner-box' style={{minHeight: min_height, fontSize: fSize}}>
        <svg className="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
          <circle
            className="active"
            pathLength="360"
            fill="transparent"
            strokeWidth="32"
            cx="192"
            cy="192"
            r="176"
          ></circle>
          <circle
            className="track"
            pathLength="360"
            fill="transparent"
            strokeWidth="32"
            cx="192"
            cy="192"
            r="176"
          ></circle>
        </svg>
      </div>
    </>
  )
}

export const getFrom = async (queryUrl, params={}, setStateName, signal='') => {
  setStateName(preValue => {
    return {...preValue, loading: true};
  })
  try {
    const res = await axios.get(queryUrl, { params: params, signal: signal });
    if (res.status === 200) {
      return {loading: false, data: res.data, err: {status: false, msg: ''}};
    } else if (res.status === 500) {
      setStateName(preValue => {
        return {...preValue, loading: false, err: {status: true, msg: res.status}};
      })
      return false;
    }
  } catch (error) {
    console.log(error);
    setStateName(preValue => {
      return {...preValue, loading: false, err: {status: true, msg: error.message}};
    })
    return false;
  }
}

// const useWindowSize = () => {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
//   // const [width, height] = useWindowSize();     // use it like this in components. Resizing will update the component as it is connected with useState.
// }


export const BreadCrumb = ({ breadCrumbAction, breadCrumbData }) => {
  if (window.location.hash === '#/' || window.location.hash === '#/#top') return;
  return (
    <div className="breadcrumb-area">
      <div className="container">
        <div className="breadcrumb-content">
          <ul>
            {/* {data.links.map((item, index) => <li key={index} className={`${data.activeLink === item.link ? 'active' : ''}`}>{data.activeLink === item.link ? `${item.name}` : <Link to={item.link}>{item.name}</Link>}</li>)} */}

            {breadCrumbData.links.map((item, index) => breadCrumbData.activeLink === item.link ? <li key={index} className="active">{item.name}</li> : <li key={index}><Link to={item.link}>{item.name}</Link></li>)}
          </ul>
        </div>
      </div>
    </div>
  )
}

const mapStateToBreadCrumb = (state) => {
  return { breadCrumbData: state.breadCrumbData };
}

export const ConnectedBreadCrumb = connect(mapStateToBreadCrumb, {breadCrumbAction})(BreadCrumb);

export const NotFound = () => {
  return (
    <div className='not-found'>
      <img src="images/err-404.jpg" alt="not found"/>
      <h3 className='mt-4'>The page you are looking for couldn't be found.</h3>
      <Link to='/' className='add_an_item_btn'>Go Back</Link>
    </div>
  )
}


export const updateLocalStorageItems = () => {                                             // Update localStorage cart, wislist items whenever changes are made in either of them.
    let { cart, wishlist } = store.getState();                                             // Can't do this in useEffect connected with cart and wishlist Due to looping problem.
    let cartItems = Object.values(cart).map(i => ({id: i.ItemId, packSizeId: i.PackSizeId}));
    let wishListItems = Object.values(wishlist).map(i => ({id: i.ItemId, packSizeId: i.PackSizeId}));
    localStorage.setItem("epharmaItemsList", JSON.stringify({sCart: cartItems, sWishlist: wishListItems}));
}

export const scrollWithOffset = (el, offsetValue) => {                                  // Scroll with offset. Works with Hashlink. Got from Hashlink docs.
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;              // Add "#top" after path in to prop to scroll to the top of the page after loading the next page.
  const yOffset = -offsetValue; 
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}


export const AutoComplete = ({ name, list, setActive=() => {}, customClass='', styles, children, keyName, itemName='Items', message='', isLoading=false, closeIcon=true }) => {
  const listRef = useRef();
  useEffect(() => {
    const onBodyClick = (event) => {                                                                                        // no need to use useRef because we wish to remove searchList on any clicks including clicks on the searchList itself.
      if (listRef.current && listRef.current.contains(event.target)) return;    
      if (!closeIcon) return;            
      setActive(false);
    }                                                                                                                        
    document.body.addEventListener('click', onBodyClick, { capture: true });                                                // Add eventlistener on component mount.
    return () => document.body.removeEventListener('click', onBodyClick, { capture: true });                                // Remove Eventlistener on component unmount.
  }, [])
  return (
    <div className={`search-results ${customClass}`} ref={listRef} style={styles}>
        {/* {searchHistory.length ? <div className='history'>
            <h4>Search History</h4>
            <ul className='list-inline'>
                {searchHistory.map(i => (<li key={i}><i className='bx bx-x' onClick={() => setSearchHistory(pre => pre.filter(a => a !== i))}></i><span onClick={() => setSearchTerm(pre => ({...pre, query: i}))}>{i}</span></li>))}
                <li className='bg-transparent' style={{boxShadow: 'none'}} onClick={() => setSearchHistory([])}>Clear all</li>
            </ul>
        </div> : ''} */}
        {isLoading ? <Spinner min_height='19rem' fSize='1.5rem'/> :
        <div className="search-content">
            <div className="search-details">
                <h5>We found <span>{list.length}</span> {itemName}</h5>
                {closeIcon ? <h5 onClick={() => setActive(false)} role='button'><i className='bx bx-x-circle'></i> Close</h5> : ''}
            </div>
            <div className="autoComplete-list">
                <ul className="list-inline mb-0">
                    {list.map(i => <li key={i[keyName]}>{React.cloneElement(children, { data: i, handleActive: setActive })}</li>)}
                    {/* {list.map(i => <li><ConnectedSearchListCard data={i} handleActive={setActive} /></li>)} */}
                </ul>
            </div>
            {message ? <p className='auto-complete-msg' style={{margin: '0.5em 0 0', color: 'orangered',fontWeight: 500, fontSize:"1.2em"}}><i className='bx bx-info-circle'></i> {message}</p> : ''}
        </div>}
    </div>
  )
}

export const mmDDyyyyDate = (date, currSeperator, requiredSeperator) => {                 // Convert dd/mm/yyyy to mm/dd/yyyy format because dd/mm/yyyy is not taken as Date() object to create new date.
  if (!date) return '';
  if (!date.includes(currSeperator)) return console.log('CurrentSeperator does not exist in received date.');
  const [dd, mm, yyyy] = date.split(currSeperator);
  return mm + requiredSeperator + dd + requiredSeperator + yyyy;                  
}  

export const JQDatePicker = ({ id, curValue, setState, name, customClass, handler, format='dd/mm/yy', required }) => {

  useEffect(() => {
    window.$(`#${id}`).datepicker({
      dateFormat: format,
      changeMonth: true,
      changeYear: true,
      yearRange: "-60:+0",
      onSelect: function() {this.focus()}, 
      onClose: function(date) {
        if (handler) return handler(date);
        setState(pre => ({ ...pre, [name]: date}))
      }      
    });
    console.log('picker rendered');
    return () => window.$(`#${id}`).datepicker( "destroy" );
  },[id, setState, name, format])             // passing handler causing problem (datepicker not closing) when user types invalid date in input.

  return <input type="text" required value={curValue} onChange={(e) => setState(pre => ({ ...pre, [name]: e.target.value}))} className={customClass} autoComplete="off" id={id} tabIndex={1}/>;
}

export const createDate = (days, months, years) => {
  var date = new Date(); 
  date.setDate(date.getDate() - days);
  date.setMonth(date.getMonth() - months);
  date.setFullYear(date.getFullYear() - years);  
  return date.toLocaleDateString('en-TT');
  // return date.toISOString().substr(0, 10);    
}

export const MyModal = ({ handleClose, name, customClass, fullscreen, child, isStatic, width='', closeIcon=true, interval='', styles }) => {

  const handleHide = () => {
    if (name === 'local-modal') {               // for local state controlled modals.
      handleClose(false);                        
    } else if (name === 'local-handler') {      // for local/redux state controlled modals with addional line of code to run with modal hide.
      handleClose();                              
    } else {                                    // default for redux state controlled modals.
      handleClose(name, false);       
    }
  }

  const handleBGClick = () => {
    if (!isStatic) return handleHide();
  }

  useEffect(() => {
    const autoClose = async () => {
      if (interval) {
        await wait(interval); 
        handleHide();
      }
    }
    autoClose();
  },[interval, handleHide])

  return (
    createPortal(
      <section className={`myModal ${customClass} ${fullscreen} epharma-global`}>
        <div className="bg-overlay" onClick={handleBGClick}></div>
        <div className={`myModal-body`} style={{...styles, maxWidth: width}}>
          {closeIcon && <i className='bx bx-x-circle modal-close-btn' onClick={handleHide}></i>}
          {child}
        </div>
      </section>,
      document.body
    )
  )
}

export const getDuration = (date) => {

  // let [byears, bmonths, bdays] = date ? date.split('-') : new Date().toLocaleDateString('en-CA').split('-');
  let [bdays, bmonths, byears] = date ? date.split('/') : new Date().toLocaleDateString('en-TT').split('/');
  let [days, months, years] = new Date().toLocaleDateString('en-TT').split('/');
  
  var by = Number.parseFloat(byears),
      bm = Number.parseFloat(bmonths),
      bd = Number.parseFloat(bdays),
      ty = Number.parseFloat(years),
      tm = Number.parseFloat(months),
      td = Number.parseFloat(days);

  if (td < bd) {
    days = (td - bd + 30);
    tm = tm - 1;
  } else {
    days = (td - bd);
  }

  if (tm < bm) {
    months = (tm - bm + 12);
    ty = ty - 1;
  } else {
    months = (tm - bm)
  }
  years = (ty - by);
  return { 
           years: years ? years : 0,
           months: months ? months : 0,
           days: days ? days : 0 
         }
}

export const wait = async (time) => await new Promise((resolve) => setTimeout(resolve, time));

export const LoginAlert = ({ type, modalAction }) => {

  const history = useHistory();
  
  switch (type) {
      case 'login':
          return (
            <Alert variant="light">
                <Alert.Heading><h4 className='text-center'>Welcome !</h4></Alert.Heading>          
                <div className="d-flex justify-content-end flex-column align-items-center">
                    <img src="/assets/img/ePharma/success.png" alt="success" className='img-fluid' style={{maxWidth: '23rem', width: 'auto'}} />
                    <h4>You Logged in successfully.</h4>
                </div>
                <div className="progress mt-4">
                    <div className="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </Alert>
        )

      case 'register':
          return (
            <Alert variant="light">
                <Alert.Heading><h4 className='text-center'>Welcome !</h4></Alert.Heading>          
                <div className="d-flex justify-content-end flex-column align-items-center">
                    <img src="/assets/img/ePharma/success.png" alt="success" className='img-fluid' style={{maxWidth: '23rem', width: 'auto'}} />
                    <h4>Registration Successfull.</h4>
                </div>
            </Alert>
        )

      case 'logout':
          return (
            <Alert variant="light">
                <Alert.Heading><h4 className='text-center'>Thank you for visiting !</h4></Alert.Heading>          
                {/* <div className="d-flex justify-content-end flex-column align-items-center">
                    <img src="/assets/img/ePharma/success.png" alt="success" className='img-fluid' style={{maxWidth: '23rem', width: 'auto'}} />
                    <h4>You successfully Logged out.</h4>
                </div> */}
                <div className="order-success-modal logout-page">
                  <i className='bx bx-check'></i>
                  <h3 style={{margin: '1.4em 0px 0.8em'}}>
                    You Successfully Logged out !'
                  </h3>
                  <p style={{fontSize: '2rem', color: '#f91e07', fontFamily: 'Poppins'}}>Please Wait !</p>
                  {/* <div className="cart-buttons mt-0 justify-content-center">
                      <button onClick={() => {history.push('/'); modalAction('LOGIN_MODAL', true)}} className="continue-button border-0">Login Again</button>
                      <Link to={'/'} className="continue-button border-0">Back to Home</Link>
                  </div> */}
              </div>
            </Alert>
        )
    
      default:
        break;
  }

}

export const MySlider = ({ name, dataList, responsive=[], customSettings={} }) => {
  const Arrow = ({ customClass, onClick, el }) => <i className={`${customClass} bx bxs-chevrons-${el}`} onClick={onClick}></i>;
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: 3000,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    className: 'product-slider',
    prevArrow: <Arrow customClass='custom-arrow' el={'left'}/>,
    nextArrow: <Arrow customClass='custom-arrow' el={'right'}/>,
    arrows: true,
    // centerMode: true,      // force slider to init in center position.
    ...customSettings         // to override above defined settings.
  };
  return <Slider {...settings} className={name} responsive={responsive}>{dataList}</Slider>;
}

export const focusArea = (globalDataAction) => {
  console.log(globalDataAction);
  window.scrollTo(0, 0);
  let x = Math.random() * 10000;
  globalDataAction({ focusArea: x });
}

export const scrollToContent = (ref, offset=100) => window.scrollTo(0, ref.current.offsetTop - offset);

export const getRequiredFieldsOnly = (list) => list.map(i => ({ 
    Category: i.Category, 
    CategoryName: i.CategoryName, 
    CompanyId: i.CompanyId,
    Description: i.Description, 
    Discount: i.Discount,
    DiscountPer: i.DiscountPer,
    ItemId: i.ItemId, 
    ItemMRP: i.ItemMRP, 
    PackSizeId: i.PackSizeId,
    ItemPackSizeList: i.ItemPackSizeList, 
    // ItemPackSizeList: [
    //   { CodeId: 11, Description: '30 g', MRP: 1000, MRPDisPer: 5, StockQty: 3, CodeId: 11, SRate: 800 },
    //   { CodeId: 12, Description: '100 g', MRP: 2000, MRPDisPer: 10, StockQty: 2, CodeId: 12, SRate: 1600 },
    //   { CodeId: 13, Description: '150 g', MRP: 3000, MRPDisPer: 20, StockQty: 0, CodeId: 13, SRate: 2500 }
    // ], 
    SRate: i.SRate, 
    StockQty: i.StockQty, 
    GroupName: i.GroupName, 
    Parent: i.Parent, 
    ParentDesc: i.ParentDesc, 
    Technicalname: i.Technicalname,
    sv_CostId: i.sv_CostId, 
    itemmstr: i.itemmstr, 
    LocationId: i.LocationId, 
    ManufacturBY: i.ManufacturBY,
    Unit: i.Unit,
    UnitName: i.UnitName,
    IsVisible: i.IsVisible, 
    ItemCode: i.ItemCode, 
    ItemImageURL: i.ItemImageURL,    
    CGST: i.CGST,
    SGST: i.SGST,
    IGST: i.IGST, 
    CGSTRATE: i.CGSTRATE, 
    SGSTRATE: i.SGSTRATE,
    IGSTRATE: i.IGSTRATE, 
}));


// https://medsy-modern.vercel.app/#

// const scrollPositions = {};

// export const useScrollPosition = (page) => {

//   useEffect(() => {

//     const pageScrollPosition = scrollPositions[page];

//     if (pageScrollPosition) {
//       setTimeout(() => {
//         window.scrollTo(0, pageScrollPosition);
//       }, 50)
//     }

//     const save = () => {
//       scrollPositions[page] = window.scrollY;
//     }

//     window.addEventListener('scroll', save)


//     return () => {
//       window.removeEventListener('scroll', save);
//     };
//   }, [page]);
// }

const UpdateScroll = ({ page, globalData, globalDataAction }) => {
  let YPosition = '';
  useEffect(() => {
    const save = () => YPosition = window.scrollY;
    window.addEventListener('scroll', save);
    return () => {
      window.removeEventListener('scroll', save);
      globalDataAction({ scrollPos: { ...globalData.scrollPos, [page]: YPosition }});
    };
  }, [page]);
}

const mapStateToUpdateScroll = (state) => {
  return { globalData: state.globalData };
}
export const ConnectedUpdateScroll = connect(mapStateToUpdateScroll, {globalDataAction})(UpdateScroll);

export const scrollPage = (pageName, globalDataAction) => {
  let { globalData } = store.getState();
  let YPosition = globalData.scrollPos[pageName];
  if (YPosition === '') return;       // ***prevents looping due to changing scrollPos from the caller components that is also using globalData.scrollPos in <UpdateScroll />
  console.log(YPosition);
  setTimeout(() => {
    window.scrollTo(0, YPosition);
    globalDataAction({ scrollPos: { ...globalData.scrollPos, [pageName]: '' }});  // ***set scrollPos to '' for that page to stop looping and uneccessary scrolls (due to rerenders of that that page) by checking for '' above. 
  }, 500);
}

export const escape = (str) => ({ swap: str.replace('&', '-'), unswap: str.replace('-', '&') });      // swap and unswap the & char with - to escape the url interference due to & character.

export const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
      // const offset = window.$('#header')[0].clientHeight;
      // console.log(offset);
      // window.scrollTo(0, offset);
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const ImageLoader = ({ src='', className='', height='', width='', alt='', fSize='1em', delay=0, styles }) => {
  const [isLoading, setLoading] = useState(true);

  const handleLoaded = () => {
    setTimeout(() => {
      setLoading(false)
    }, 800 + delay);
  }

  useEffect(() => {
    setLoading(true);
  },[src])
  
  return (
    <div className='img-loader-box'>
      <img className={className} height={height} width={width} src={src} onLoad={handleLoaded} alt={alt} style={{opacity: isLoading ? '0' : '1', ...styles}} />
      <img className='load' loading='lazy' style={{display: isLoading ? 'block' : 'none', fontSize: fSize}} src="/assets/img/ePharma/loader.svg" alt="" />
    </div>
  )
}

export const getFallbackImg = () => {
  let { compCode } = store.getState();
  let companies = {
    [defaultId]: 'no-image.png',
    [ePharmaId]: 'ePharma-no-image.png',
    [TAKE_HOME_ID]: 'takeHome-no-image.png',
    [XYZ_ID]: 'no-image.png',
  }
  let imgUrl = companies[compCode] ? companies[compCode] : 'no-image.png';

  return `/assets/img/fallback/${imgUrl}`;
}