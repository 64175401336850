import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { modalAction, loginStatusAction, loaderAction, userInfoAction } from "../../../../actions";
import axios from "axios";
import { JQDatePicker, createDate, getDuration, handleNumberInputs, useFetch } from "../utilities";
import { hasTable, RAJE_RESTAURANT_ID, XYZ_ID } from "../../../../constants";
import { encrypt } from "../../default/utilities";


const LoginModal = ({modalAction, isLoggedIn, loginStatusAction, compCode, loaderAction, userInfoAction, userInfo}) => {

    const [loginData, setLoginData] = useState({phone: '', password: ''});
    const [regData, setRegData] = useState({
        Name: '',        
        EncCompanyId: '',
        PartyCode: '0',
        PartyId: '0',
        UserId: '0',
        RegMob1: '',
        Email: '', 
        Address: '',
        UserPassword: '',
        UserType: '',
        Address2: '',
        City: '',
        State: 3,
        StateName: 'West Bengal',
        Pin: '',        
        DOB: '',
        DOBstr: '',
        Age: '',
        AgeMonth: '',
        AgeDay: '',
        IsDOBCalculated: 'N',
        GenderDesc: 'Male',
        Gender: 104,
        Country: 1,
        MemberId: '',

        RegNo: "",                                // Unused fields.
        Aadhaar: "",
        Salutation: "",
        Qualification: "",
        SpecialistId: '',
        AnniversaryDate: "",
        AnniversaryDatestr: "",
        compName: "",
        compAddress: "",
        compState: "",
        compPin: "",
        compPhone1: "",
        compPhone2: "",
        compMail: ""
    });
    const [statesList, setStatesList] = useState([{Description: 'West Bengal', CodeId: 3}]); 
    const stateSelectRef = useRef();
    const genderSelectRef = useRef();
    const [stateSelectOpen, setStateSelectOpen] = useState(false);
    const [genderSelectOpen, setGenderSelectOpen] = useState(false);
    const [tabActive, setTabActive] = useState('login');
    const [otp, setOTP] = useState({isOpen: false, recievedValue: 'null', enteredValue: '', sent: false, verified: false, read_only: false});
    const [loginError, setLoginError] = useState({status: false, message: ''});
    const [genderData, genderDataLoading, genderDataError] = useFetch(`${process.env.REACT_APP_BASE_URL}/api/Values`, compCode);

  useEffect(() => {
    const onBodyClick = (event) => {
        if (stateSelectRef.current && stateSelectRef.current.contains(event.target)) return;                          // Return if click is triggered from search field form or it's inner elements.
        setStateSelectOpen(false); 
        if (genderSelectRef.current && genderSelectRef.current.contains(event.target)) return;                          // Return if click is triggered from search field form or it's inner elements.
        setGenderSelectOpen(false);                                                                                       // close select element only if click is triggered rest of the elements (outer body).
    }
    document.body.addEventListener('click', onBodyClick, { capture: true });                                                // Add eventlistener on component mount.
    return () => document.body.removeEventListener('click', onBodyClick, { capture: true });                                // Remove Eventlistener on component unmount.
  }, [])

  let activeState = statesList.filter(i => i.CodeId === regData.State)[0]?.Description; 
//   let activeGender = genders.filter(i => i.CodeId === regData.State)[0]?.Description; 


    useEffect(() => {

        let usertype = 'Customer';
        if (hasTable) {
            usertype = 'SALESPOINT';
        }
        setRegData((preVlaues) => ({...preVlaues, EncCompanyId: compCode, UserType: usertype}));
    },[compCode])

    useEffect(() => {
        const getStates = async () => {
            loaderAction(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Values/1`);
            loaderAction(false);
            let states = res.data.map(i => ({Description: i.Description, CodeId: i.CodeId}));
            setStatesList(states);
        }
        getStates();
    },[loaderAction])

    useEffect(() => {
        if (isLoggedIn) {
            setRegData(pre => ({
                ...pre,
                Name: userInfo.Name, 
                EncCompanyId: userInfo.EncCompanyId,
                PartyCode: userInfo.PartyCode,
                PartyId: userInfo.PartyId,
                UserId: userInfo.UserId,
                RegMob1: userInfo.RegMob1,
                Email: userInfo.Email,
                Address: userInfo.Address,
                UserPassword: userInfo.UserPassword,
                UserType: userInfo.UserType,
                Address2: userInfo.Address2,
                City: userInfo.City,
                State: userInfo.State,
                StateName: userInfo.StateName,
                Pin: userInfo.Pin,
                DOB: new Date(userInfo.DOB).toLocaleDateString('en-TT'),
                DOBstr: new Date(userInfo.DOB).toLocaleDateString('en-TT'),
                Age: userInfo.Age,
                AgeMonth: userInfo.AgeMonth,
                AgeDay: userInfo.AgeDay,
                IsDOBCalculated: userInfo.IsDOBCalculated,
                GenderDesc: userInfo.GenderDesc,
                Gender: userInfo.Gender,
                Country: userInfo.Country,
                MemberId: userInfo.MemberId,
            }))
        }
    }, [isLoggedIn, userInfo])

    const handleLoginForm = (e) => {
        const { name, value} = e.target;
        setLoginData({...loginData, [name]: value})
    }

    const handleRegForm = (e) => {
        const { name, value} = e.target;
        setRegData({...regData, [name]: value})
    }    

    const handleRegisterFormSubmit = async (e) => {
        e.preventDefault();
        if (!isLoggedIn && otp.verified) {
            if (regData.RegMob1.length < 10) return alert('phone number is invalid, please try again.');
            if (regData.UserPassword.length < 4) return alert('Minimum length for password is 4.');
            let status = await makeRegisterationRequest(regData);
            if (status) {
                let loginStatus = await refreshUserInfo(regData);
                if (loginStatus) {
                    loginStatusAction(true);
                    modalAction('LOGIN_MODAL', false);
                    modalAction('ALERT_MODAL', true, 'register');
                } else {
                    alert('We could not log you in, Please log in again manually.');
                }
            } 
            console.log(regData);
        }
    } 
    
    const handleNext = async () => {
        if (!isLoggedIn && !otp.sent) {
            if (regData.RegMob1.length < 10) return alert('please enter a valid phone number.');
            const userExist = await checkExistingUser();
            if (userExist) return;
            console.log('sending OTP');
            const receivedOtp = await makeOtpRequest();
            setOTP({...otp, isOpen: true, sent: true, recievedValue: receivedOtp});
        } else if (otp.sent) {
            if (compCode !== XYZ_ID) {
                if (otp.recievedValue !== otp.enteredValue) return alert('Wrong OTP.');
            }
            console.log('otp matched');
            setOTP({...otp, isOpen: false, verified: true, read_only: true});
            setAllFields(true);
        }
    }

    const makeRegisterationRequest = async (params) => {
        console.log(params);
        try {
            loaderAction(true);
            const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/UserReg`, params);
            loaderAction(false);
            console.log(res.data);
            if (res.data[0] === 'Y') { 
                return true;
            } else {
                alert('Something Went wrong, Please try again later.');
                return false;
            }      
        } catch (err) {
            console.log(err);
            return false;
        }
    } 
    
    const refreshUserInfo = async (params) => {
        try {
            loaderAction(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${params.RegMob1}&UP=${encodeURIComponent(params.UserPassword)}&CID=${compCode}`);
            loaderAction(false);
            if (res.data.UserId === 0) {
              return false;
            } else {
              console.log(res.data);
              userInfoAction(res.data);
              return true;
            }
        } catch (err) {
            alert(err)
        }
    }

    const handleLoginFormSubmit = (e) => {
        e.preventDefault();
        if (loginData.phone.length < 10) return alert('Please enter a valid phone number.');
        if (loginData.phone.length < 4) return alert('Minimum length for password is 4.');
        makeLoginRequest();
    }

    const makeLoginRequest = async () => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserAuth?UN=${loginData.phone}&UP=${encodeURIComponent(loginData.password)}&CID=${compCode}`);
        loaderAction(false);
        if (res.data.Remarks === 'INVALID') {
          setLoginError({status: true, message: 'The username or password is incorrect.'});
        } else if (res.data.Remarks === 'NOTINCOMPANY') {

            setRegData(pre => ({
                ...pre,
                Name: res.data.Name,
                EncCompanyId: res.data.EncCompanyId,
                PartyCode: '',
                PartyId: '',
                UserId: res.data.UserId,
                RegMob1: res.data.RegMob1,
                Email: res.data.Email,
                Address: res.data.Address,
                UserPassword: '',
                UserType: res.data.UserType,
                Address2: res.data.Address2,
                City: res.data.City,
                State: res.data.State,
                StateName: res.data.StateName,
                Pin: res.data.Pin,
                DOB: new Date(res.data.DOB).toLocaleDateString('en-TT'),
                DOBstr: new Date(res.data.DOB).toLocaleDateString('en-TT'),
                Age: res.data.Age,
                AgeMonth: res.data.AgeMonth,
                AgeDay: res.data.AgeDay,
                IsDOBCalculated: 'N',
                GenderDesc: res.data.GenderDesc,
                Gender: res.data.Gender,
                Country: res.data.Country,
                MemberId: res.data.MemberId,
                

                RegNo: "",                                // Unused fields.
                Aadhaar: "",
                Salutation: "",
                Qualification: "",
                SpecialistId: '',
                AnniversaryDate: "",
                AnniversaryDatestr: "",
                compName: "",
                compAddress: "",
                compState: "",
                compPin: "",
                compPhone1: "",
                compPhone2: "",
                compMail: ""
            }))
            setOTP(pre => ({ ...pre, verified: true})); 
            setAllFields(true);
            setTabActive('register');

            setLoginError({status: false, message: ''});

        } else {
          loginStatusAction(true);
          modalAction('LOGIN_MODAL', false);
          userInfoAction(res.data);
          modalAction('ALERT_MODAL', true, 'login');
          let userLoginData = {
            Address: res.data.Address,
            Address2: res.data.Address2,
            City: res.data.City,
            Country: res.data.Country,
            Email: res.data.Email,
            EncCompanyId: res.data.EncCompanyId,
            Name: res.data.Name,
            PartyCode: res.data.PartyCode,
            PartyId: res.data.PartyId,
            Pin: res.data.Pin,
            RegMob1: res.data.RegMob1,
            State: res.data.State,
            StateName: res.data.StateName,
            UserId: res.data.UserId,
            UserType: res.data.UserType,

            DOB: res.data.DOB,
            Age: res.data.Age,
            AgeMonth: res.data.AgeMonth,
            AgeDay: res.data.AgeDay,
            IsDOBCalculated: res.data.IsDOBCalculated,
            GenderDesc: res.data.GenderDesc,
            Gender: res.data.Gender,
            MemberId: res.data.MemberId,

            UserPassword: res.data.UserPassword
          };
            localStorage.setItem("userLoginData", encrypt({ phone: loginData.phone, password: res.data.UserPassword, compCode: compCode }));
            userInfoAction(userLoginData);
         }
    }

    const checkExistingUser = async () => {
        if (regData.RegMob1.length > 9) {
          loaderAction(true);
          const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg?UN=${regData.RegMob1}`);
          loaderAction(false);
          if (res.data === 'Y') {
            setLoginError({status: true, message: 'This number is already registered.'});
            setLoginData(preValue => {
              return { ...preValue, phone: regData.RegMob1 }
            })
            setRegData(preValue => {
              return { ...preValue, RegMob1: '' }
            })
            setTabActive('login');
            return true;
          } else {
            setLoginError({status: false, message: ''});
            return false;
          }
        }
    }

    const makeOtpRequest = async () => {
        loaderAction(true);
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/UserReg/0?name=Subscriber&mob=${regData.RegMob1}`);
        loaderAction(false);
        if (res.status === 200) {
          console.log(res.data);
          return res.data;
        }
        alert('An Error Occured, Try again later.');
        return 'asdfasdasdf';
    }
    
    // const makeOtpRequest = () => '0';  

    const handleDate = (props) => {
        const { Age, AgeMonth, AgeDay, currField, currValue }  = props;
        let calculatedDOB;
        if (currField === 'Age') {
            calculatedDOB = createDate(AgeDay || 0, AgeMonth || 0, currValue);
        } else if (currField === 'AgeDay') {
            calculatedDOB = createDate(currValue, AgeMonth || 0, Age || 0);
        } else if (currField === 'AgeMonth') {
            calculatedDOB = createDate(AgeDay || 0, currValue, Age || 0);
        }
        setRegData(pre => ({...pre, DOB: calculatedDOB, DOBstr: calculatedDOB, IsDOBCalculated: 'Y'}));
    }
    
    const handleNumberInputsWithDate = (e, setStateName) => {
        const {name, value} = e.target;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
          setStateName(preValue => {
             return {...preValue, [name]: value};
          });
          let currValues = { Age: regData.Age, AgeMonth: regData.AgeMonth, AgeDay: regData.AgeDay, currField: name, currValue: value };
          handleDate(currValues);
        }
    }

    const calculateDuration = (date) => {
        setRegData(pre => ({ 
            ...pre, IsDOBCalculated: 'N', 
            Age: getDuration(date).years, 
            AgeMonth: getDuration(date).months, 
            AgeDay: getDuration(date).days, 
            DOB: date,
            DOBstr: date,
        }))
    }

    const [allFields, setAllFields] = useState(false);

    const resendOTP = async () => {
        const receivedOtp = await makeOtpRequest();
        setOTP({...otp, recievedValue: receivedOtp});
    }

// --------------------- Forgot Password -----------------------------------------------------------------------

    const [forgotPasswordModal, setForgotPasswordModal] = useState({ isOpen: false, passwordRecoveryNumber: '', error: '' });

    const handleForgotPasswordForm = (e) => {
        e.preventDefault();
        console.log(forgotPasswordModal);
        sendPassword();
    }

    const sendPassword = async () => {
        try {
            loaderAction(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Userauth/0?UN=${forgotPasswordModal.passwordRecoveryNumber}&CID=${compCode}&Type=FP`, {});
            loaderAction(false);
            if (res.data === 'Y') {
                setForgotPasswordModal(pre => ({ ...pre, error: 'Your Password has been sent to your registered mobile number. please check !' }));
            } else {
                setForgotPasswordModal(pre => ({ ...pre, error: 'This number is not Registered.' }));
            }            
        } catch (error) {
            loaderAction(false);
            alert('Something went wrong, Please try again later!');
        }
    }

    return (
        <div className='d-flex justify-content-center'>
            <div id='loginForm'>
                <i className='bx bx-x-circle close-custom-modal' onClick={() => modalAction('LOGIN_MODAL', false)}></i>
                <div className='top d-flex justify-content-around align-items-center'>
                    {tabActive !== 'forgotPassword' ?
                        <>
                            <label htmlFor="mode" role="button" style={{fontSize: '1em'}}><h5 className={`${tabActive === 'register' && 'text-secondary opacity-50'}`}>Login</h5></label>
                            <label className="toggle-switch">
                            <input id='mode' type="checkbox" checked={tabActive === 'login' ? false : true} onChange={() => {setTabActive(tabActive === 'login' ? 'register' : 'login'); setLoginError({status: false, message: ''})}}/>
                            <div className="toggle-switch-background">
                                <div className="toggle-switch-handle"></div>
                            </div>
                            <span className="check"></span>
                            </label>
                            <label htmlFor="mode" role="button" style={{fontSize: '1em'}}><h5 className={`${tabActive === 'login' && 'text-secondary opacity-50'}`}>REGISTER</h5></label>
                        </>  
                        :
                        <h5 className={``}><i className='bx bxs-lock' style={{color: '#d63384'}}></i> FORGOT PASSWORD</h5>
                    }
                </div>
                <div className='bottom'>
                    <div className="tab-content overflow-hidden pt-2">
                        <div id="tabFade-pane-1" className={`tab-pane fade ${tabActive === 'login' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-1">
                            <form onSubmit={handleLoginFormSubmit}>
                                <div className='input-group mb-30'>
                                    <input type='text' onChange={handleLoginForm} required name='phone' value={loginData.phone} autoComplete='true' maxLength={10}/>
                                    <label className={`${loginData.phone && 'active'}`} htmlFor='phone'>Phone Number</label>
                                </div>
                                <div className='input-group mb-20'>
                                    <input type='password' onChange={handleLoginForm} required name='password' value={loginData.password} autoComplete='true'/>
                                    <label className={`${loginData.password && 'active'}`} htmlFor='password'>Your Password</label>
                                </div>
                                <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p>
                                <Link to='#' onClick={() => setTabActive('forgotPassword')} className='text-primary'>Forgot Password ?</Link>
                                <button className='add_an_item_btn loginBtn'>LOGIN</button>
                            </form>
                        </div>

                        <div id="tabFade-pane-3" className={`tab-pane fade ${tabActive === 'forgotPassword' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-3">
                            <form onSubmit={handleForgotPasswordForm}>
                                <div className='input-group mb-30'>
                                    <input type='text' onChange={(e) => handleNumberInputs(e, setForgotPasswordModal)} value={forgotPasswordModal.passwordRecoveryNumber} required name='passwordRecoveryNumber' autoComplete='true' maxLength={10}/>
                                    <label className={`${forgotPasswordModal.passwordRecoveryNumber && 'active'}`} htmlFor='phone'>Phone Number</label>
                                </div>
                                <p className="text-danger" style={{display: forgotPasswordModal.error ? 'block' : 'none'}}>{forgotPasswordModal.error}</p>
                                <Link to='#' onClick={() => setTabActive('login')} className='text-primary'>Back To Login ?</Link>
                                <button className='add_an_item_btn loginBtn'>GET OTP</button>
                            </form>
                        </div>

                        <div id="tabFade-pane-2" className={`tab-pane fade ${tabActive === 'register' && 'show active'}`} role="tabpanel" aria-labelledby="tabFade-2">
                            <form onSubmit={handleRegisterFormSubmit}>
                                {/* <div className='input-group mb-30'>
                                    <input type='text' onChange={handleRegForm} id="RegMob1" name='RegMob1' value={regData.RegMob1} autoComplete='true'/>
                                    <label className={`${regData.RegMob1 && 'active'}`} htmlFor='RegMob1'>Phone Number</label>
                                </div> */}
                                {/* <div className='input-group mb-20'>
                                    <input type='password' onChange={handleRegForm} id="UserPassword" name='UserPassword' value={regData.UserPassword} autoComplete='true'/>
                                    <label className={`${regData.UserPassword && 'active'}`} htmlFor='UserPassword'>Choose a Password</label>
                                </div> */}
                                {/* {!isLoggedIn && <div className="col-md-12">   
                                    {otp.isOpen && <div id="cbox-info" className="checkout-form-list create-account">
                                        <input placeholder="Enter your OTP.." type="text" name='otp' value={otp.enteredValue} onChange={(e) => setOTP({...otp, enteredValue: e.target.value})}/>
                                    </div>}
                                </div>} */}
                                {/* <p className="text-danger" style={{display: loginError.status ? 'block' : 'none'}}>{loginError.message}</p> */}


                                <div className="row">

                                    <div className="col-12">
                                        <div className="input-group mb-4">
                                            <input type="text" name='RegMob1' readOnly={isLoggedIn || otp.read_only ? true : false} value={regData.RegMob1} required onChange={(e) => handleNumberInputs(e, setRegData)} maxLength='10'/>
                                            <label className={`${regData.RegMob1 && 'active'}`}>Phone Number <span className="required">*</span></label>
                                        </div>
                                    </div>
                                    {otp.isOpen && <div className="col-12">
                                        <div className="input-group create-account">
                                            <input placeholder="Enter your OTP.." type="text" name='otp' value={otp.enteredValue} required onChange={(e) => setOTP({...otp, enteredValue: e.target.value})}/>
                                            <p className="text-primary ms-auto mt-2" role="button" onClick={resendOTP}>Resend OTP.</p>
                                        </div>
                                    </div>}
                                    {!allFields && <>
                                        <div className="order-button-payment">
                                            <p>We'll send an OTP, please enter it to complete creating your account.</p>
                                        </div>
                                        <div className="col-12">
                                            <button className='add_an_item_btn loginBtn' onClick={handleNext} type="button">NEXT</button>
                                        </div>
                                    </>}

                                    {allFields && <div className="row">
                                        <div className="col-12">
                                            <div className="input-group mb-4">
                                                <input type="text" name='Name' value={regData.Name} required onChange={handleRegForm} tabIndex={1}/>
                                                <label className={`${regData.Name && 'active'}`}>Your Name <span className="required">*</span></label>
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="input-group mb-4">
                                                <JQDatePicker id={'user_DOB'} setState={setRegData} handler={calculateDuration} curValue={regData.DOB} name={'DOB'} tabIndex={1} required />
                                                <label className={`${regData.DOB && 'active'}`}>DOB <span className="required">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="input-group mb-4">
                                                {/* <input type="text" name='Age' value={regData.Age} required onChange={handleRegForm}/> */}
                                                <input name="Age" value={regData.Age} onChange={(e) => handleNumberInputsWithDate(e, setRegData)} tabIndex={1} type='text' maxLength={2} id="txtPtAge" />
                                                <label className={`${regData.Age !== '' && 'active'}`}>Years </label>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="input-group mb-4">
                                                {/* <input type="text" name='AgeMonth' value={regData.AgeMonth} required onChange={handleRegForm}/> */}
                                                <input name="AgeMonth" value={regData.AgeMonth} onChange={(e) => handleNumberInputsWithDate(e, setRegData)} tabIndex={1} type='text' maxLength={2} id="txtPtAgeMonth"/>
                                                <label className={`${regData.AgeMonth !== '' && 'active'}`}>Months </label>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="input-group mb-4">
                                                {/* <input type="text" name='AgeDay' value={regData.AgeDay} required onChange={handleRegForm}/> */}
                                                <input name="AgeDay" value={regData.AgeDay} onChange={(e) => handleNumberInputsWithDate(e, setRegData)} tabIndex={1} type='text' maxLength={2} id="txtPtAgeDay"/>
                                                <label className={`${regData.AgeDay !== '' && 'active'}`}>Days </label>
                                            </div>
                                        </div>


                                        <div className="col-12">
                                            <div className="input-group mb-4">
                                                <input type="text" name='Address' value={regData.Address} required onChange={handleRegForm} tabIndex={1}/>
                                                <label className={`${regData.Address && 'active'}`}>Address <span className="required">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group mb-4">
                                                <input type="text" name='Address2' value={regData.Address2} onChange={handleRegForm} tabIndex={1}/>
                                                <label className={`${regData.Address2 && 'active'}`}>Apartment (optional)</label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group mb-4">
                                                <input type="text" name='City' value={regData.City} onChange={handleRegForm} tabIndex={1}/>
                                                <label className={`${regData.City && 'active'}`}>City</label>
                                            </div>
                                        </div>
                                        {/* <div className="col-6">
                                            <div className="input-group mb-4">
                                                <input type="text" name='Country' value='India' readOnly={true} required onChange={() => {}}/>
                                                <label className="active">Country <span className="required">*</span></label>
                                            </div>
                                        </div> */}
                                        <div className="col-6">
                                            <div className="input-group mb-4 justify-content-between flex-column" ref={stateSelectRef}>
                                                <label className="position-relative">State <span className="required">*</span></label>
                                                <div className={`nice-select select-filter-category w-100 ${stateSelectOpen && 'open'}`} tabIndex="1" onClick={() => setStateSelectOpen(!stateSelectOpen)}>
                                                    <span className="current">{activeState}</span>
                                                    <ul className="list" style={{maxHeight: '14em', overflow: 'auto'}}>
                                                        {statesList.map((item, index) => <li key={index} onClick={() => {setRegData({...regData, StateName: item.Description, State: item.CodeId}); setStateSelectOpen(false)}} className={`option ${regData.State === item.CodeId && 'selected focus'}`}>{item.Description}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group mb-4 justify-content-between flex-column" ref={genderSelectRef}>
                                                <label className="position-relative">Gender <span className="required">*</span></label>
                                                <div className={`nice-select select-filter-category w-100 ${genderSelectOpen && 'open'}`} tabIndex="1" onClick={() => setGenderSelectOpen(!genderSelectOpen)}>
                                                    <span className="current">{regData.GenderDesc}</span>
                                                    <ul className="list">
                                                        {genderData.map((item, index) => <li key={index} onClick={() => {setRegData({...regData, GenderDesc: item.Description, Gender: item.CodeId}); setGenderSelectOpen(false)}} className={`option ${regData.State === item.CodeId && 'selected focus'}`}>{item.Description}</li>)}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group mb-4">
                                                <input type="text" name='Pin' value={regData.Pin} required onChange={(e) => handleNumberInputs(e, setRegData)} maxLength='6' tabIndex={1}/>
                                                <label className={`${regData.Pin && 'active'}`}>Pincode / Zip <span className="required">*</span></label>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="input-group mb-4">
                                                <input type="email" name='Email' value={regData.Email} onChange={handleRegForm} tabIndex={1}/>
                                                <label className={`${regData.Email && 'active'}`}>Email Address</label>
                                            </div>
                                        </div>
                                        {!isLoggedIn && <>
                                            <div className="col-6">
                                                <div className="input-group mb-4 create-account">
                                                    <input type="password" name='UserPassword' value={regData.UserPassword} required onChange={handleRegForm} autoComplete="false" tabIndex={1}/>
                                                    <label className={`${regData.UserPassword && 'active'}`}>Choose a password  <span className="required">*</span></label>
                                                </div>
                                                
                                            </div>
                                        </>} 
                                        <div className="col-12">
                                            {loginError.status && <p className="text-danger">{loginError.message}</p>}
                                            <button className='add_an_item_btn loginBtn' type="submit">REGISTER</button>
                                        </div>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToLoginModal = (state) => {
    return { isLoggedIn: state.isLoggedIn, compCode: state.compCode, userInfo: state.userInfo };
}

export default connect(mapStateToLoginModal, {modalAction, loginStatusAction, loaderAction, userInfoAction})(LoginModal);