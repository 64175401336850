import { connect } from "react-redux";
import { BSN_ID, RAJE_RESTAURANT_ID, TAKE_HOME_ID, XYZ_ID, bhsId, defaultId, ePharmaId, hasTable } from "../../constants";
import { useState } from "react";
import { wait } from '../../components/companiens/default/utilities';

const CssRoute = ({ compCode, compInfo }) => {

    const [cover, setCover] = useState(true);
    
    // if (compCode === BSN_ID || compCode === bhsId) {
    //     import('../CSS/bsn/styles.css');
    //     import('../CSS/bsn/responsive.css');
    //     if (compCode === bhsId) {
    //         import('../CSS/bhs.css');
    //         import('../CSS/bsn/modifier.css');
    //     }
    // } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || compCode === defaultId) {
    //     import('../CSS/ePharma/ePharma.css');
    //     import('../CSS/ePharma/ePharma-responsive.css');
    //     if (compCode === ePharmaId) {
    //         import('../CSS/ePharma/ePharma-modify.css');
    //     } else if (compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
    //         import('../CSS/takeHome/takeHome.css');
    //     }
    // } else {
    //     import('../CSS/default/default-modify.css');
    // }

    (async () => {
        if (compCode === BSN_ID || compCode === bhsId) {
            await import('../CSS/bsn/styles.css');
            await import('../CSS/bsn/responsive.css');
            if (compCode === bhsId) {
                await import('../CSS/bhs.css');
                await import('../CSS/bsn/modifier.css');
            }
        } else {
            await import('../CSS/ePharma/ePharma.css');
            await import('../CSS/ePharma/ePharma-responsive.css');
        } 
    
        if (compCode === ePharmaId) {
            await import('../CSS/ePharma/ePharma-modify.css');
        } else if (compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
            await import('../CSS/takeHome/takeHome.css');
        } else {
            await import('../CSS/default/default-modify.css');
        }
        await wait(800);
        setCover(false);
    })();

    if (cover) {
        return (
            <div className="d-flex justify-content-center align-items-center default-global" style={{position: 'fixed', inset: 0, zIndex: 111111, background: 'white'}}>  
                <div className="d-flex flex-column gap-4 align-items-center">
                    {(() => {
                        if (compCode === TAKE_HOME_ID) {
                            return <img src="/img/logo/takeHome.png" alt="Logo" style={{width: 'clamp(19em, 28vw, 30rem)'}} />;
                        } else if (compCode === ePharmaId) {
                            return <img src="/img/logo/epharma.png" alt="Logo" style={{width: 'clamp(16em, 21vw, 22rem)'}} className="mb-4" />;
                        } else if (compCode === XYZ_ID) {
                            return <img src="/img/logo/XYZ-LOGO.png" alt="Logo" style={{width: 'clamp(19rem, 18vw, 19rem)', marginBottom: '2rem'}} />;
                        } else if (compCode === BSN_ID) {
                            return <img src="./assets/img/bankura seva logo.webp" alt="Logo" style={{width: 'clamp(9rem, 15vw, 13rem)'}} />;
                        } else if (compCode === defaultId) {
                            return <img src="/img/gbooks-round-logo.png" alt="Logo" style={{width: 'clamp(9rem, 15vw, 13rem)'}} className="mb-4"/>;
                        } else if (compCode === RAJE_RESTAURANT_ID) {
                            return <img src={`https://erp.gsterpsoft.com/Content/CompanyLogo/827.jpeg`} alt="Logo" style={{width: 'clamp(9rem, 15vw, 13rem)'}} className="mb-4"/>;
                        }           // https://erp.gsterpsoft.com/Content/CompanyLogo/${compInfo.LogoUrl}
                    })()}
                    <div className="text-center w-100" style={{minWidth: '25vw'}}>
                        <h3>Loading...</h3>
                        <div className="progress mt-4">
                            <div className="progress-bar progress-bar-striped progress-bar-animated w-100" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToCssRoute = (state) => {
    return { compCode: state.compCode, compInfo: state.compInfo };
}
  
export default connect(mapStateToCssRoute, {})(CssRoute);


