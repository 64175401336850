import { connect } from "react-redux";
import Header from "../companiens/default/header";
// import Home from "../companiens/default/home";
import Specialists from "../companiens/default/specialists";
import Home from "../companiens/default/home";
import Footer from "../companiens/default/footer";
import BottomNav from "../companiens/default/bottomNav";
import BedStatus from "../companiens/default/bedStatus";
import LabTests from "../companiens/default/labTests";
import PatientQueue from "../companiens/default/patientQueue";
import Pharmacy from "../companiens/default/pharmacy";
import Prescription from "../companiens/default/prescription";
import MyOrders from "../companiens/default/myOrders";
import Services from "../companiens/default/services";
import Product from "../companiens/default/productPage";
import Cart from "../companiens/default/cartPage";
import Wishlist from "../companiens/default/wishlist";
import Checkout from "../companiens/default/checkout";
import Dashboards from "../companiens/default/dashboards";
import { BSN_ID, HAYATT_ID, RAJE_RESTAURANT_ID, TAKE_HOME_ID, XYZ_ID, amNursingId, bhsId, blessId, defaultId, ePharmaId, hasTable } from "../../constants";
import BSNHeader from '../companiens/bsn/Header';
import BSNFooter from '../companiens/bsn/Footer';
import BSNHome from '../companiens/bsn/Home';
import BSNServices from '../companiens/bsn/ourServices';
import AboutUs from "../companiens/bsn/AboutUs";
import BSNDoctors from "../companiens/bsn/Doctors2";
import BSNDoctor from "../companiens/bsn/DoctorProfile";
import BSNFaq from "../companiens/bsn/Faq";
import BSNTestimonials from "../companiens/bsn/Testimonial";
import BSNService from "../companiens/bsn/Departments/Gynecology";
import BSNContactUs from "../companiens/bsn/ContactUs";
import BSNCareers from "../companiens/bsn/Careers";
import BSNBlogs from "../companiens/bsn/Blogs";
import BSNBlog from "../companiens/bsn/Blogs/blog";
import DoctorProfile from "../companiens/default/profiles/doctorProfile";
import BedCategories from "../companiens/bsn/BedCatagories";
import SocialWorks from "../companiens/bsn/SocialWorks";
import HealthCenter from "../companiens/bhs/HealthCenter";
import { NotFound } from "../companiens/default/utilities";
import Invoices from "../companiens/default/prints/invoices";

import EPharmaHeader from '../companiens/ePharma/header';
import EPharmaHome from '../companiens/ePharma/home';
import EPharmaFooter from '../companiens/ePharma/footer';
import EPharmaAboutUs from '../companiens/ePharma/aboutUs';
import EPharmaPrivacyPolicy from '../companiens/ePharma/polycies/privacyPlicy';
import EPharmaReturnPolicy from '../companiens/ePharma/polycies/returnPolicy';
import EPharmaTermsCondition from '../companiens/ePharma/polycies/termsCondition';
import EPharmaContactUs from '../companiens/ePharma/contactUs';
import EPharmaProductPage from '../companiens/ePharma/productPage/productPage';
import EPharmaCartPage from '../companiens/ePharma/cartPage';
import EPharmaWishlistPage from '../companiens/ePharma/wishlist';
import EPharmaCheckout from '../companiens/ePharma/checkout';
import EPharmaBottomNav from '../companiens/ePharma/bottomNav';
import EPharmaOrders from '../companiens/ePharma/orders';
import EPharmaInvoice from '../companiens/ePharma/invoicePrint';
import EPharmaModals from '../companiens/ePharma/modals';
import Modals from "../companiens/default/modals";
import HealthPakages from "../companiens/default/healthPakages";
import HealthPakage from "../companiens/default/healthPakages/healthPakage";
import FilterPage from "../companiens/default/filterPage";



const HeaderRoute = ({ compCode }) => {

    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNHeader />;
    } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaHeader />;
    } else if (compCode === HAYATT_ID ) {
        return;
    } else {
        return <Header />
    }
}
const HomePage = ({ compCode, match }) => {

    if (compCode === defaultId) {
        return <Specialists />;
    } else if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNHome />;
    } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        return <EPharmaHome />
    } else if (compCode === HAYATT_ID) {
        return;
    } else if (hasTable) {
        // return <FilterPage match={match} />
        return <EPharmaCheckout />
    } else {
        return <Home />;
    }
}
const FooterPage = ({ compCode }) => {

    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNFooter />;
    } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaFooter />;
    } else if (compCode === HAYATT_ID ) {
        return;
    } else {
        return <Footer />;
    }
}
const BottomNavBar = ({ compCode }) => {

    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaBottomNav />;
    } else if (compCode === HAYATT_ID ) {
        return;
    } else {
        return <BottomNav />;
    }
}
const BedStatusView = ({ compCode }) => {

    if (compCode === '') {
        return;
    } else {
        return <BedStatus />;
    }
}
const AboutUsPage = ({ compCode }) => {

    if (compCode === BSN_ID || compCode === bhsId) {
        return <AboutUs />;
    } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        return <EPharmaAboutUs />;
    } else {
        return;
    }
}
const ContactUsPage = ({ compCode }) => {

    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNContactUs />;
    }  else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        return <EPharmaContactUs />;
    } else {
        return;
    }
}
const InvoicePrintPage = ({ compCode, match }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaInvoice match={match} />;
    } else {
        return <Invoices match={match} />;
    }
}
const LabTestPage = ({ compCode }) => {
    if (compCode === '') {
        return;
    } else if (compCode === BSN_ID) {
        return <NotFound />;
    } else {
        return <LabTests />;
    }
}

const HealthPakagesPage = ({ compCode }) => {
    if (compCode === defaultId || compCode === BSN_ID) {
        return <HealthPakages/>;
    } else {
        return <NotFound/>
    }
}

const HealthPakagePage = ({ compCode }) => {
    if (compCode === defaultId || compCode === BSN_ID) {
        return <HealthPakage/>;
    } else {
        return <NotFound/>
    }
}

const PatientQueuePage = ({ compCode }) => {
    if (compCode === '') {
        return;
    } else {
        return <PatientQueue  />;
    }
}
const PharmacyPage = ({ compCode }) => {
    if (compCode === defaultId) {
        return <EPharmaHome />;
    } else {
        return <Pharmacy />;
    }
}
const PrescriptionPage = ({ compCode, match }) => {
    if (compCode === '') {
        return;
    } else {
        return <Prescription match={match} />;
    }
}
const MyOrdersPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaOrders />;
    } else {
        // return <EPharmaOrders />;
        return <MyOrders />;
    }
}
const ServicesPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNServices />;
    } else {
        return <Services />
    }
}
const ProductPage = ({ compCode, match }) => {
    if (compCode === '') {
        return;
    } else if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable || compCode === defaultId) {
        return <EPharmaProductPage match={match} />;
    } else {
        return <Product match={match} />;
    }
}
const CartPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaCartPage />;
    } else {
        return <Cart />;
    }
}
const WishlistPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaWishlistPage />;
    } else {
        return <Wishlist />;
    }
}
const DoctorsPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNDoctors compCode={compCode} />;
    } else {
        return;
    }
}
const DoctorPage = ({ compCode, match }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNDoctor match={match} />;
    } else {
        return <DoctorProfile match={match} />;
    }
}
const DashboardsPages = ({ compCode }) => {
    if (compCode === '') {
        return;
    } else {
        return <Dashboards />
    }
}
const FAQPage = ({ compCode}) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNFaq />;
    } else {
        return;
    }
}
const TestimonialsPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNTestimonials />;
    } else {
        return;
    }
}
const ServicePage = ({ compCode, match }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNService match={match} />;
    } else {
        return;
    }
}
const CareersPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNCareers />;
    } else {
        return;
    }
}
const BlogsPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNBlogs />;
    } else {
        return;
    }
}
const BlogPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BSNBlog />;
    } else {
        return;
    }
}
const BedCategoriesPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <BedCategories />;
    } else {
        return;
    }
}
const SocialWorksPage = ({ compCode }) => {
    if (compCode === BSN_ID || compCode === bhsId) {
        return <SocialWorks />;
    } else {
        return;
    }
}

const HealthCenterPage = ({ compCode }) => {
    if (compCode === bhsId) {
        return <HealthCenter />;
    } else {
        return;
    }
}

const PrivacyPolicyPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        return <EPharmaPrivacyPolicy/>;
    } else {
        return;
    }
}

const TermsConditionPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        return <EPharmaTermsCondition/>;
    } else {
        return;
    }
}

const ReturnPolicyPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID) {
        return <EPharmaReturnPolicy/>;
    } else {
        return;
    }
}

const CheckoutPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaCheckout />;
    } else {
        return <Checkout />;
    }
}

const ModalsPage = ({ compCode }) => {
    if (compCode === ePharmaId || compCode === TAKE_HOME_ID || compCode === XYZ_ID || hasTable) {
        return <EPharmaModals />;
    } else {
        return <Modals />;
    }
}

const mapStateToProps = (state) => {
    return { compCode: state.compCode };
}

export default connect(mapStateToProps, {})(HeaderRoute);
export const HomeRoute = connect(mapStateToProps, {})(HomePage);
export const FooterRoute = connect(mapStateToProps, {})(FooterPage);
export const BottomNavRoute = connect(mapStateToProps, {})(BottomNavBar);
export const BedStatusRoute = connect(mapStateToProps, {})(BedStatusView);
export const AboutUsRoute = connect(mapStateToProps, {})(AboutUsPage);
export const InvoicePrintRoute = connect(mapStateToProps, {})(InvoicePrintPage);
export const LabTestPageRoute = connect(mapStateToProps, {})(LabTestPage);
export const HealthPakagesRoute = connect(mapStateToProps, {})(HealthPakagesPage);
export const HealthPakageRoute = connect(mapStateToProps, {})(HealthPakagePage);
export const PatientQueueRoute = connect(mapStateToProps, {})(PatientQueuePage);
export const PharmacyRoute = connect(mapStateToProps, {})(PharmacyPage);
export const PrescriptionRoute = connect(mapStateToProps, {})(PrescriptionPage);
export const MyOrdersRoute = connect(mapStateToProps, {})(MyOrdersPage);
export const ServicesRoute = connect(mapStateToProps, {})(ServicesPage);
export const ProductRoute = connect(mapStateToProps, {})(ProductPage);
export const CartRoute = connect(mapStateToProps, {})(CartPage);
export const CheckoutRoute = connect(mapStateToProps, {})(CheckoutPage);
export const WishlistRoute = connect(mapStateToProps, {})(WishlistPage);
export const DashboardsRoute = connect(mapStateToProps, {})(DashboardsPages);
export const DoctorsRoute = connect(mapStateToProps, {})(DoctorsPage);
export const DoctorRoute = connect(mapStateToProps, {})(DoctorPage);
export const FAQRoute = connect(mapStateToProps, {})(FAQPage);
export const TestimonialsRoute = connect(mapStateToProps, {})(TestimonialsPage);
export const ServiceRoute = connect(mapStateToProps, {})(ServicePage);
export const ContactUsRoute = connect(mapStateToProps, {})(ContactUsPage);
export const CareersRoute = connect(mapStateToProps, {})(CareersPage);
export const BlogsRoute = connect(mapStateToProps, {})(BlogsPage);
export const BlogRoute = connect(mapStateToProps, {})(BlogPage);
export const BedCategoriesRoute = connect(mapStateToProps, {})(BedCategoriesPage);
export const SocialWorksRoute = connect(mapStateToProps, {})(SocialWorksPage);
export const HealthCenterRoute = connect(mapStateToProps, {})(HealthCenterPage);

export const ReturnPolicyRoute = connect(mapStateToProps, {})(ReturnPolicyPage);
export const TermsConditionRoute = connect(mapStateToProps, {})(TermsConditionPage);
export const PrivacyPolicyRoute = connect(mapStateToProps, {})(PrivacyPolicyPage);
export const ModalsRoute = connect(mapStateToProps, {})(ModalsPage);