import axios from "axios";
import { connect } from "react-redux";
import { userInfoAction, compInfoAction, loaderAction, globalDataAction, siteDataAction, filterAction, modalAction } from "../../../actions";
import { useEffect } from "react";
import { UseFavicon, useDocumentTitle, ScrollUpIcon } from "../default/utilities";
import { getRequiredFieldsOnly } from "../ePharma/utilities";
import { hasTable, initSiteData } from "../../../constants";

const InitHeader = ({ compCode, userInfo, loaderAction, userInfoAction, compInfoAction, compInfo, globalDataAction, siteDataAction, filterAction, globalData, modalAction, isLoggedIn }) => {

    useEffect(() => {
        if (!isLoggedIn) return;
        if (hasTable) {
            modalAction('TABLE_SELECTION_MODAL', true)
        }
    }, [isLoggedIn])
    useEffect(() => {
        getCompanyDetails(compCode);
    }, [compCode])

    useEffect(() => {
        let controller1 = new AbortController();
        const getCompanyList = async (companyCode, userId, signal) => {                  
            if (!companyCode) return console.log('no companyCode received');               
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/CompMast?CID=${companyCode}&UID=${userId}`, { params: {}, signal: signal });
            if (res) {  
                let parentCompany = res.data.find(i => i.EncCompanyId === compCode);                                                                                                          
                userInfoAction({companyList: res.data, selectedCompany: parentCompany });              
            } else {
                alert('Something went wrong.');
            }
        }
        getCompanyList(compCode, userInfo.UserId, controller1.signal);
        return () => controller1.abort();
    }, [compCode, userInfo.UserId])

    useEffect(() => {
        const getMembersList = async (companyCode, userId, memberId) => {
            if (!userId) return;
            try {      
                loaderAction(true);
                const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/member/Get?UserId=${userId}&CID=${companyCode}`, {});
                if (res.data) {
                    const parentMember = res.data.AccPartyMemberMasterList.find(i => i.MemberId === memberId);
                    if (parentMember) {
                        userInfoAction({MembersList: res.data, selectedMember: parentMember});
                    } else {
                        userInfoAction({MembersList: res.data});
                        console.log('No parent member found');
                    }
                }
            } catch (error) {
                alert('Something went wrong please Refresh or try after some time. 29');
            }
            loaderAction(false);
        }
        getMembersList(compCode, userInfo.UserId, userInfo.MemberId);
    },[loaderAction, compCode, userInfo.UserId, userInfo.MemberId, userInfoAction])

    const getCompanyDetails = async (companyCode) => {
        try {      
            loaderAction(true);
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/CompMast?CID=${companyCode}`, {});
            if (res.data.COMPNAME && res.data.EncCompanyId) {
                compInfoAction(res.data);
                // userInfoAction({selectedCompany: res.data});
                if (!globalData.location.LocationId) {                                   // to keep location saved in localStorage.
                    localStorage.setItem(`userLocation_${compCode}`, JSON.stringify({ LocationId: res.data.LocationId }));   
                    globalDataAction({ location: { LocationId: res.data.LocationId} });            
                }
                // globalDataAction({ location: { LocationId: 1293} });
            } else {
                alert('Invalid Company Id recieved ! Please enter correct one to continue.');
            }
        } catch (error) {
            alert('Something went wrong please Refresh or try after some time.');
            console.log('No company received. 49');
        }
        loaderAction(false);
    }

    useDocumentTitle(compInfo.COMPNAME);

    useEffect(() => {
        const getProductsData = async () => {
            // if (!globalData.location.LocationId) return;
            // siteDataAction('ADD_DATA', initSiteData);               // set loading state. 
            siteDataAction(initSiteData);               // set loading state. 
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/Pharma?CID=${compCode}&LOCID=${globalData.location.LocationId}`, {});
            if (res.status === 200) {
                const products = getRequiredFieldsOnly(res.data.itemMasterCollection);
                siteDataAction({ ...res.data, itemMasterCollection: products, isLoading: false }); 
                const medicineSubLinks = res.data.LinkSubCategoryList.filter(i => i.Parent === 8756);                   // where parent category is 'Medicine'.
                filterAction('categories', medicineSubLinks);    
            }
        }
        getProductsData();
      },[compCode, filterAction, siteDataAction, globalData.location.LocationId])

    return (
        <>
            <UseFavicon LogoUrl={compInfo.LogoUrl} />
            <ScrollUpIcon compCode={compCode} />
        </>
    )
}


const mapStateToProps = (state) => {
    return { 
        compCode: state.compCode,
        compInfo: state.compInfo,
        isLoggedIn: state.isLoggedIn,
        userInfo: state.userInfo,
        globalData: state.globalData
        // cart: state.cart,
        // siteData: state.siteData,
        // isHeaderActive: state.isHeaderActive         
    };
}
  
export default connect(mapStateToProps, {userInfoAction, compInfoAction, loaderAction, globalDataAction, filterAction, siteDataAction, modalAction})(InitHeader);

