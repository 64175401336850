export const initAppState = {
    // compCode: "yFObpUjTIGhK9%2B4bFmadRg==",
    isLoggedIn: false,
    userInfo: {
        Salutation: "",
        Name: "",
        EncCompanyId: "",
        PartyCode: "",
        RegMob1: "",
        Gender: "",
        GenderDesc: "",
        Address: "",
        Age: "",
        AgeMonth: "",
        AgeDay: "",
        UserPassword: "",
        Department: { dName: "All", SubCode: 0 },
        UserType: "",
        Qualification: "",
        RegNo: "",
        SpecialistId: 0,
        MPartyCode: "",
        PartyId: 0,
        MemberId: "",
        selectedCompany: {},
        MembersList: {},
        companyList: [],
        selectedMember: {},
        UserId: 0,
        location: {},
        Country: 1,
        State: "3",
        StateName: "West Bengal",
        City: "",
        Pin: "",
        Address2: "",
        DOB: "",
        AnniversaryDate: "",
        Aadhaar: "",
        IsDOBCalculated: "N",
        compName: "",
        compAddress: "",
        compState: "",
        compPin: "",
        compPhone1: "",
        compPhone2: "",
        compMail: ""
    },
    isMobile: false,
    // compInfo: { COMPNAME: "XYZ Hospitality Solutions", EncCompanyId: compCode, LogoUrl: "612.png" },         // use recieved compcode to avoid race conditons in pharmacy page, others fields will be updated with api call.
    isToastActive: { status: false, item: {} },
    isLoading: false,
    cart: { pharmacy: {}, labTests: {} },
    wishlist: { pharmacy: {}, labTests: {} },
    siteData: { isLoading: true, itemMasterCollection: [], ParentCategoryList: [ { Value: "" } ], LinkSubCategoryList: [] },
    modals: {
        LOGIN_MODAL: { state: false, data: { mode: "PATIENT" } },
        APPN_BOOKING_MODAL: { state: false, data: "" },
        SCHEDULE_MODAL: { state: false, data: "" },
        MEMBER_MODAL: { state: false, data: "" },
        DOC_DETAILS_MODAL: { state: false, data: "" },
        EDIT_USER_MODAL: { state: false, data: "" },
        MEMBER_PROFILE_MODAL: { state: false, data: "" },
        MEMBER_SELECT_MODAL: { state: false, data: "" },
        LABTEST_BOOK_MODAL: { state: false, data: "" },
        QUICKVIEW_MODAL: { state: false, data: "" },
        CATEGORY_MODAL: { state: false, data: "" },
        USER_INFO_MODAL: { state: false, data: "" },
        ORDER_SUCCESS_MODAL: { state: false, data: "" },
        ORDER_CANCELLED_MODAL: { state: false, data: "" },
        RETURN_POLICY_MODAL: { state: false, data: "" },
        ALERT_MODAL: { state: false, data: "" },
        PRESCRIPTION_MODAL: { state: false, data: "" },
        LOCATION_MODAL: { state: false, data: "" },
        HEALTH_PAKAGE_MODAL: { state: false, data: "" },
        TABLE_SELECTION_MODAL: { state: false, data: "" },
        CART_EDIT_MODAL: { state: false, data: "" }
    },
    bookingInfo: {
        selectedAppnDate: "",           // used to detect active item of date button slider in bookingForm.
        Doctor: { Name: "", SpecialistDesc: "", Qualification: "", Mobile: "" },
        UnderDoctId: "",
        AppointDate: "",
        AppTime: "",
        TimeSlotId: "",
        companyId: ""
    },
    isHeaderActive: true,
    globalData: {
        focusArea: "0",
        location: { LocationId: 0 },
        scrollPos: { home: "", filterPage: "" },
        prescription: { patient: { docName: "", docAddress: "" } }
    },
    breadCrumbData: { links: [ { name: "Home", link: "/" } ], activeLink: "/" }, 
    quickviewItem: {}, 
    filterData: { categories: [], selectedCategoryId: "", products: [], searchString: 'init' }
}


export const logoutObject = {
    isLoggedIn: initAppState.isLoggedIn,
    userInfo: initAppState.userInfo,
    cart: initAppState.cart,
    wishlist: initAppState.wishlist,
    bookingInfo: initAppState.bookingInfo,
}